<div *ngIf="section">
  <div class="modal-header bg-primary">
    <button
      type="button"
      class="close"
      (click)="decline()"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p
      [innerHtml]="
        isEnglish
          ? this.privacyPolicyData?.description_en
          : this.privacyPolicyData?.description_ar
      "
    ></p>
  </div>
</div>
<div class="privacy-policy" *ngIf="!section">
  <div class="modal-body text-center">
    <button class="btn btn-primary bordered-btn" (click)="changeLang()">
      {{ isEnglish ? "الإنجليزية" : "English" }}
    </button>
    <p
      [innerHtml]="
        isEnglish
          ? this.privacyPolicyData?.description_en
          : this.privacyPolicyData?.description_ar
      "
    ></p>
  </div>
</div>
