<header class="d-flex space-between">
  <div class="header-left">
    <h1>{{ "TRIP_DETAILS" | translate }}</h1>
    <p class="header-data">
      {{ "ORDER_NUMBER" | translate }} - #{{ this.orderData?.order_id }}
    </p>
  </div>
  <div class="header-right">
    <span (click)="this.closePopup()" class="close"
      ><i class="icon-close"></i
    ></span>
  </div>
</header>
<div class="body-wrapper custom-scrollbar">
  <ng-container *ngFor="let orderTrip of listTripData">
    <div class="body-part">
      <div class="trip-left-box">
        <div class="trip-id">
          <span class="trip-data" (click)="redirectToTripDetail(orderTrip.id)"
            >{{ orderTrip.trip_id }}
          </span>
        </div>
        <div class="truck-info-table">
          <table>
            <thead>
              <tr>
                <th class="pl5">{{ "TRUCK_PRICE" | translate }}</th>
                <th>{{ orderTrip?.trip_price?.price }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" class="additional-charges-title pl5">
                  {{ "ADDITIONAL_CHARGES" | translate }} :
                </td>
              </tr>
              <ng-container *ngIf="orderTrip?.additional_charges.length > 0">
                <ng-container
                  *ngFor="
                    let additionalChargeData of orderTrip?.additional_charges
                      | slice: -2
                  "
                >
                  <tr>
                    <td class="pl5">
                      {{ additionalChargeData?.charges?.charges_en }} :
                    </td>
                    <td>
                      <span class="price">{{
                        additionalChargeData?.sr_price
                          ? additionalChargeData?.sr_price
                          : 0
                      }}</span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="table-total">
          <div class="pl5">
            <span class="bold-text">{{ "TOTAL" | translate }}</span>
          </div>
          <div>
            <span class="bold-text">{{
              orderTrip?.trip_price?.trip_price_with_additional_charges
            }}</span>
          </div>
        </div>
      </div>
      <div class="trip-detail">
        <div class="trip-data-top">
          <div class="title-button">
            <div class="buttons-part">
              <button
                [ngClass]="
                  orderTrip?.is_bayan_sync === tripSyncFlag.zero
                    ? 'btn btn-small btn-primary'
                    : 'btn btn-small btn-primary'
                "
                (click)="
                  orderTrip?.is_bayan_sync !== tripSyncFlag.zero
                    ? downloadBayanWaybillFile(
                        orderTrip?.id,
                        orderFileType.BAYAN
                      )
                    : ''
                "
                [disabled]="orderTrip?.is_bayan_sync === tripSyncFlag.zero"
              >
                {{ "BAYAN" | translate }}
              </button>
              <button
                class="btn btn-small btn-primary"
                (click)="
                  downloadBayanWaybillFile(orderTrip?.id, orderFileType.WAYBILL)
                "
              >
                {{ "WAY_BILL" | translate }}
              </button>
              <button
                class="btn btn-small btn-primary"
                (click)="redirectToChat(orderTrip?.trip_id)"
              >
                {{ "CHAT" | translate }}
              </button>
            </div>
          </div>
          <div class="trip-info-container">
            <div class="wrap-container">
              <div class="col-3 w-date-time">
                <span class="title">{{ "LOADING_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span>{{
                      orderTrip?.loading_date
                        | customDate: moment24DateOnlyFormat
                    }}</span>
                    <span
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.loading_date
                          | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-3 w-date-time"
                *ngIf="
                  orderTrip.next_status !== 'on_the_way_trip' &&
                  orderTrip.next_status !== 'at_unloading_location' &&
                  orderTrip.next_status === ''
                "
              >
                <span class="title">{{ "DELIVERED_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span
                      *ngIf="
                        orderTrip.next_status !== 'on_the_way_trip' &&
                        orderTrip.next_status !== 'at_unloading_location' &&
                        orderTrip?.offloading_date
                      "
                      >{{
                        orderTrip?.offloading_date
                          | customDate: moment24DateOnlyFormat
                      }}</span
                    >
                    <span
                      *ngIf="
                        orderTrip.next_status !== 'on_the_way_trip' &&
                        orderTrip.next_status !== 'at_unloading_location' &&
                        orderTrip?.offloading_date
                      "
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.offloading_date
                          | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-3 w-date-time"
                *ngIf="orderTrip.next_status !== '' && orderTrip?.eta_date"
              >
                <span class="title">{{ "ETA_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span
                      *ngIf="
                        orderTrip.next_status !== '' && orderTrip?.eta_date
                      "
                      >{{
                        orderTrip?.eta_date | customDate: moment24DateOnlyFormat
                      }}</span
                    >
                    <span
                      *ngIf="
                        orderTrip.next_status !== '' && orderTrip?.eta_date
                      "
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.eta_date | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-3 driver-name">
                <span class="title">{{ "DRIVER_NAME" | translate }}</span>
                <span class="desc" title="{{ orderTrip.driver?.name }}">{{
                  orderTrip.driver?.name
                }}</span>
              </div>
              <div class="col-3">
                <span class="title">{{ "NATIONAL_ID" | translate }}</span>
                <span class="desc">{{ orderTrip?.driver?.national_id }}</span>
              </div>
              <div class="col-3 w-truck">
                <span class="title">{{ "TRUCK" | translate }} #</span>
                <span class="desc m-number">{{
                  orderTrip.vehicle?.full_plate_number
                }}</span>
              </div>
              <div class="col-3 w-mobile">
                <span class="title">{{ "MOBILE" | translate }} #</span>
                <span class="desc m-number">
                  {{ orderTrip?.driver?.absher_mobile ?? "-" }}
                </span>
                <ng-container>
                  <span *ngIf="orderTrip?.driver?.mobile" class="desc">
                    |
                  </span>
                  {{ orderTrip?.driver?.mobile }}
                </ng-container>
              </div>
              <div class="col-3 w-truck-type">
                <span class="title">{{ "TRUCK_TYPE" | translate }}</span>
                <span
                  class="desc"
                  title="{{ orderTrip?.vehicle?.truck?.name }}"
                  >{{
                    orderTrip?.vehicle?.truck?.name
                      ? orderTrip?.vehicle?.truck?.name
                      : "-"
                  }}</span
                >
              </div>
              <div class="col-3 w-weight">
                <span class="title">{{ "WEIGHT" | translate }}</span>
                <span class="desc">{{
                  this.orderData?.weight + " " + this.measurementUnits.TON
                }}</span>
              </div>

              <div class="col-3 container-section">
                <div class="container-title">
                  <span class="title">{{ "CONTAINER" | translate }} #</span>
                </div>
                <span
                  *ngFor="
                    let container of orderTrip?.trip_containers.slice(0, 1);
                    let i = index
                  "
                  class="desc"
                >
                  <span title="{{ container?.container_number }}">{{
                    container?.container_number | truncate: 11
                  }}</span>
                </span>
              </div>
              <div class="col-3 container-section">
                <span class="title">{{ "SEAL" | translate }} #</span>
                <div
                  *ngFor="
                    let container of orderTrip?.['trip_containers'].slice(
                      0,
                      1
                    );
                    let i = index
                  "
                  class="seal-details"
                >
                  <span class="desc" title="{{ container?.seal_number }}">{{
                  container?.seal_number ?? '-' | truncate:9
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="trip-data-bottom">
          <div *ngIf="orderTrip?.is_canceled !== isCanceled" class="trip-left">
            <div class="shipment-in-progress">
              <i class="icon-mark"></i>
              <span>{{ "SHIPMENT_IN_PROGRESS" | translate }}</span>
              <div class="trip-date-time" *ngIf="orderTrip?.loading_date">
                <span>{{
                  (orderTrip.trip_statuses[0]?.status === "at_loading_location"
                    ? orderTrip.trip_statuses[0]?.date
                    : orderTrip.trip_statuses[1]?.status ===
                      "at_loading_location"
                    ? orderTrip.trip_statuses[1]?.date
                    : orderTrip.trip_statuses[2]?.date
                  ) | customDate: moment24DateTimeFormat
                }}</span>
              </div>
            </div>
            <div class="shipment-on-way">
              <i
                class="{{
                  orderTrip?.next_status === 'at_unloading_location' ||
                  orderTrip?.next_status === ''
                    ? 'icon-mark'
                    : 'icon-un-mark'
                }}"
              ></i>
              <span>{{ "SHIPMENT_ON_THE_WAY" | translate }}</span>
              <div class="trip-date-time" *ngIf="orderTrip?.loading_date">
                <span>{{
                  (orderTrip.trip_statuses[0]?.status === "on_the_way_trip"
                    ? orderTrip.trip_statuses[0]?.date
                    : orderTrip.trip_statuses[1]?.status === "on_the_way_trip"
                    ? orderTrip.trip_statuses[1]?.date
                    : orderTrip.trip_statuses[2]?.date
                  ) | customDate: moment24DateTimeFormat
                }}</span>
              </div>
            </div>
            <div class="delivered">
              <i
                class="{{
                  orderTrip?.next_status === '' ? 'icon-mark' : 'icon-un-mark'
                }}"
              ></i>
              <span>{{ "DELIVERED" | translate }}</span>
              <div class="trip-date-time" *ngIf="orderTrip?.loading_date">
                <span>{{
                  (orderTrip.trip_statuses[0]?.status ===
                  "at_unloading_location"
                    ? orderTrip.trip_statuses[0]?.date
                    : orderTrip.trip_statuses[1]?.status ===
                      "at_unloading_location"
                    ? orderTrip.trip_statuses[1]?.date
                    : orderTrip.trip_statuses[2]?.date
                  ) | customDate: moment24DateTimeFormat
                }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="orderTrip?.is_canceled === isCanceled" class="trip-left">
            <div class="canceled">
              <div class="alert-container">
                <div class="alert danger">
                  <strong class="reason-title"
                    >{{ "CANCELLATION_REASON" | translate }}:</strong
                  >
                  <div class="cancellation-reason-text">
                    <span class="cancelled-reason">{{
                      orderTrip?.canceled_reason
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="canceled" *ngIf="orderTrip?.canceled_by">
              <div class="alert-container">
                <div class="alert danger">
                  <span class="reason-text">
                    <strong>{{ "TRIP_CANCEL_BY" | translate }}:</strong>
                    {{ getCancelTripDetailsforSpSr(orderTrip) }}&nbsp;{{
                      "AT" | translate
                    }}&nbsp;{{
                      orderTrip?.cancelled_at | customDate: momentDateTimeFormat
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
