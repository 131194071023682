<div class="d-col d-col-2">
  <a
    *ngIf="permissionList.length > 0"
    routerLinkActive="active"
    (click)="openPermissions(openModelTemplate)"
    class="btn bordered-btn btn-medium"
  >
    {{ "GIVE_PERMISSIONS" | translate }}</a
  >
</div>
<ng-template #openModelTemplate>
  <div class="modal-header bg-primary">
    <div class="table-content">
      <div class="table-wrapper">
        <table class="tbl-permission">
          <caption></caption>
          <thead>
            <tr>
              <th>{{ "ALL_PERMISSIONS" | translate }}</th>
              <th *ngFor="let item of allCheckBoxes; let i = index">
                <label [for]="item.id">
                  <input
                    type="checkbox"
                    [checked]="item.checked"
                    [name]="item.id"
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    [id]="item.id"
                    (change)="allCheckBoxChange($event, i)"
                  />
                  <span class="custom-checkbox"></span>
                  {{ item.name }}
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let permission of permissionList;
                let i = index;
                trackBy: trackByFn
              "
            >
              <td>{{ transformString(permission[0]) }}</td>
              <td *ngFor="let box of permission[1][0]; let j = index">
                <label [for]="box.id" *ngIf="box.is_checkbox === 1">
                  <input
                    [checked]="checkedList?.includes(box.id)"
                    (change)="checkChecked($event, box.id, j)"
                    type="checkbox"
                    [name]="box.name"
                    [id]="box.id"
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                  />
                  <span class="custom-checkbox"></span>
                </label>
                <label *ngIf="box.is_checkbox === 0" for="" class="no-checkbox">
                  -
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="buttons d-flex just-end">
      <a (click)="decline()" class="btn popup-btn-small bordered-btn">
        {{ "CANCEL" | translate }}
      </a>

      <a
        (click)="sendDataToParent()"
        class="btn popup-btn-small btn-secondary"
        >{{ "CONFIRM" | translate }}</a
      >
    </div>
  </div>
</ng-template>
