<div class="wrapper">
  <div class="sidebar">
    <div class="logo-area">
      <picture>
        <source srcset="assets/images/common/logo.webp" type="image/webp" />
        <source srcset="assets/images/common/logo.png" type="image/png" />
        <img
          src="assets/images/common/logo.png"
          alt="Profile"
          title="Profile"
          width="118px"
          height="70px"
        />
      </picture>
    </div>
    <ul>
      <li routerLinkActive="active">
        <a [routerLink]="[userRoleURL + staticRoutes.DEFAULT.DASHBOARD]"
          ><i class="icon-dashboard"></i>{{ "DASHBOARD" | translate }}</a
        >
      </li>
      <li routerLinkActive="active" *ngIf="isServiceProviderRoute">
        <a [routerLink]="[staticRoutes.PROVIDER.MANAGE_DRIVER]">
          <i class="icon-Drivers"></i>
          {{ "DRIVERS" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceProviderRoute">
        <a [routerLink]="[staticRoutes.PROVIDER.MANAGE_VEHICLE]">
          <i class="icon-Trucks"></i>
          {{ "TRUCKS_TITLE" | translate }}
        </a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="[userRoleURL + staticRoutes.DEFAULT.DIRECT_ORDERS]">
          <i class="icon-Direct-Orders"></i>
          {{ "DIRECT_ORDERS" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceProviderRoute">
        <a [routerLink]="[staticRoutes.PROVIDER.CONTRACTS]">
          <i class="icon-Contracts"></i>
          {{ "CONTRACT" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceRequesterRoute">
        <a [routerLink]="[staticRoutes.REQUESTER.MY_CONTRACTS]">
          <i class="icon-Contracts"></i>
          {{ "CONTRACTS" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceRequesterRoute">
        <a [routerLink]="[staticRoutes.REQUESTER.ORDER_HISTORY]">
          <i class="icon-Order-History"></i>
          {{ "ORDER_HISTORY" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceProviderRoute">
        <a [routerLink]="[staticRoutes.PROVIDER.ORDER_HISTORY]">
          <i class="icon-Order-History"></i>
          {{ "ORDER_HISTORY" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceProviderRoute">
        <a [routerLink]="[staticRoutes.PROVIDER.CHAT]">
          <i class="icon-chat"></i>
          {{ "CHAT" | translate }}
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isServiceRequesterRoute">
        <a [routerLink]="[staticRoutes.REQUESTER.CHAT]">
          <i class="icon-chat"></i>
          {{ "CHAT" | translate }}
        </a>
      </li>
    </ul>
  </div>
  <main>
    <div class="top-bar">
      <div class="d-flex just-end">
        <div class="detail">
          <a class="chat-message" [routerLink]="['chat']">
            <i class="icon-chat"></i>
            <span class="chat-message-count">
              {{ this.unReadMessageCount }}
            </span>
          </a>
          <a class="bell" (click)="this.openNotification()">
            <i class="icon-notification"></i>
            <span class="notification-count">
              {{ this.unReadCount }}
            </span>
            <div
              class="notification-box d-flex"
              id="notification-box"
              appDivCloseOnClick
            >
              <div class="notification-title d-flex space-between">
                <h3>{{ "NOTIFICATIONS" | translate }}</h3>
                <button
                  class="btn btn-close"
                  (click)="this.closeNotification()"
                >
                  <i class="icon-close"></i>
                </button>
              </div>

              <div class="notification-list d-flex">
                <ng-container
                  *ngFor="let notification of this.notificationData"
                >
                  <a
                    (click)="
                      notification.seen === 0
                        ? this.notificationRead(notification?.id)
                        : null
                    "
                    [routerLink]="[
                      notification.type === this.SectionType.MEMBERSHIP &&
                      this.isServiceProviderRoute
                        ? this.getRoutesRole() +
                          this.routesConstant.contractsOrderDetails +
                          notification?.model_id
                        : notification.type === this.SectionType.MEMBERSHIP &&
                          this.isServiceRequesterRoute
                        ? this.getRoutesRole() +
                          this.routesConstant.myContractsOrderDetails +
                          notification?.model_id
                        : notification.type === this.SectionType.ORDER
                        ? this.getRoutesRole() +
                          this.routesConstant.directOrdersView +
                          notification?.model_id
                        : notification.type === this.SectionType.MEMBERSHIP &&
                          this.isAdminRoute
                        ? this.getRoutesRole() +
                          this.routesConstant.orderContracts +
                          notification?.model_id
                        : notification.type === this.SectionType.CHAT
                        ? this.getRoutesRole() + this.routesConstant.chat
                        : notification.type === this.SectionType.SPECIAL_ORDER
                        ? this.getRoutesRole() +
                          this.routesConstant.specialOrderOrderDetails +
                          notification.model_id
                        : this.isServiceProviderRoute &&
                          notification.type === this.SectionType.OFFERS
                        ? this.getRoutesRole() +
                          this.routesConstant.offerOrders +
                          notification?.model_id
                        : this.isServiceRequesterRoute &&
                          notification.type === this.SectionType.OFFERS
                        ? this.getRoutesRole() +
                          this.routesConstant.offerListOfferOrders +
                          notification?.model_id
                        : ''
                    ]"
                  >
                    <div class="notification d-flex space-between">
                      <div class="notification-icon-text d-flex">
                        <div
                          class="notification-icon"
                          [ngClass]="
                            notification.type === this.SectionType.ORDER
                              ? this.notificationIconBgClass.bgDirectOrder
                              : notification.type ===
                                this.SectionType.MEMBERSHIP
                              ? this.notificationIconBgClass.bgContract
                              : notification.type ===
                                this.SectionType.SPECIAL_ORDER
                              ? this.notificationIconBgClass.bgSpecialOrder
                              : notification.type === this.SectionType.OFFERS
                              ? this.notificationIconBgClass.bgOffers
                              : notification.type === this.SectionType.CHAT
                              ? this.notificationIconBgClass.bgBell
                              : ''
                          "
                        >
                          <i
                            [ngClass]="
                              notification.type === this.SectionType.ORDER
                                ? this.notificationIconClass.iconDirectOrders
                                : notification.type ===
                                  this.SectionType.MEMBERSHIP
                                ? this.notificationIconClass.iconContracts
                                : notification.type === this.SectionType.CHAT
                                ? this.notificationIconClass.iconChat
                                : notification.type ===
                                  this.SectionType.SPECIAL_ORDER
                                ? this.notificationIconClass.iconSpecialOrders
                                : notification.type === this.SectionType.OFFERS
                                ? this.notificationIconClass.iconOffers
                                : ''
                            "
                          ></i>
                        </div>
                        <div class="notification-detail">
                          <h5>
                            {{ this.OrderType[notification?.type] }}:
                            {{
                              notification?.model_id
                                ? notification?.model_id
                                : notification?.title
                            }}
                          </h5>
                          <p>
                            {{
                              notification.type === "chat"
                                ? this.getDecodeMsg(notification?.extra)
                                : notification.body
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="notification-date-time d-flex">
                        <span>{{
                          notification?.created_at
                            ? this.getNotificationTimeConvert(
                                notification?.created_at
                              )
                            : ""
                        }}</span>
                        <span>{{
                          this.getNotificationTimeConvert(
                            notification?.created_at,
                            this.notificationTime
                          )
                        }}</span>
                      </div>
                    </div>
                  </a>
                </ng-container>
                <div
                  class="no-notification"
                  *ngIf="this.notificationData.length < 1"
                >
                  <h3>{{ "NO_NOTIFICATION_YET" | translate }}</h3>
                  <i class="icon-notification"></i>
                  <span>{{ "NOTIFY_MSG" | translate }}</span>
                </div>
              </div>

              <div class="view-notification">
                <div
                  class="view-notification-inner border-right d-flex align-center"
                >
                  <a
                    (click)="this.closeNotification()"
                    [routerLink]="[this.getRoutesRole() + '/notification-list']"
                    class="notify"
                    >{{ "VIEW_ALL_NOTIFICATIONS" | translate }}</a
                  >
                </div>
                <div class="view-notification-inner d-flex align-center">
                  <span
                    title="{{ 'MARK_ALL_AS_READ' | translate }}"
                    (click)="this.seenAllNotifications()"
                    class="mark_as_read"
                  >
                    {{ "MARK_ALL_AS_READ" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </a>
          <div class="company-detail">
            <div class="company_logo">
              <picture>
                <img
                  id="profileLogo"
                  [src]="userLogo"
                  alt="Profile"
                  title="Profile"
                  width="40px"
                  height="40px"
                  (error)="this.onErrorProfileImage($event)"
                />
              </picture>
            </div>
            <div class="profile-detail dropdown" appDropdown>
              <div class="select">
                <span class="selected">
                  <span class="profile-title" id="headerName"
                    >{{ userName }}
                  </span>
                  <span class="profile-type"
                    >{{
                      (this.isServiceProviderRoute
                        ? "SERVICE_PROVIDER"
                        : "SERVICE_REQUESTER"
                      ) | translate
                    }}
                  </span>
                </span>
                <div class="caret">
                  <i class="icon-arrow-down"></i>
                </div>
              </div>
              <ul class="menu">
                <li
                  routerLinkActive="active"
                  [routerLink]="[userRoleURL + '/profile']"
                >
                  {{ "ACCOUNT_DETAIL" | translate }}
                </li>
                <li routerLinkActive="active" (click)="openModal(terms)">
                  {{ "TERMS_AND_CONDITION" | translate }}
                </li>
                <li
                  routerLinkActive="active"
                  (click)="openModal(privacyPolicy)"
                >
                  {{ "PRIVACY_POLICY" | translate }}
                </li>
                <li
                  routerLinkActive="active"
                  [routerLink]="[userRoleURL + '/contact-us']"
                >
                  {{ "CONTACT_US" | translate }}
                </li>
                <app-logout-section (confirmLogout)="onLogout($event)">
                </app-logout-section>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </main>
</div>
<ng-template #terms>
  <app-terms-and-condtion-section
    [section]="sectionType"
    (closeModal)="decline()"
  ></app-terms-and-condtion-section>
</ng-template>
<ng-template #privacyPolicy>
  <app-privacy-policy-section
    [section]="sectionType"
    (closeModal)="decline()"
  ></app-privacy-policy-section>
</ng-template>
<app-spinner></app-spinner>
