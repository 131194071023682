import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminLayoutComponent, DefaultLayoutComponent } from "./containers";
import { AdminManagerProfileComponent } from "./views/admin/admin-manager/admin-manager-profile/admin-manager-profile.component";
import { P403Component } from "./views/error/403.component";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { ForgotPasswordComponent } from "./views/forgot-password/forgot-password.component";
import { OrderHistoryComponent } from "./views/order-history/order-history.component";
import { OtpVerificationComponent } from "./views/otp-verification/otp-verification.component";
import { PaymentCommonStatusComponent } from "./views/payment-common-status/payment-common-status.component";
import { PaymentInterceptComponent } from "./views/payment-intercept/payment-intercept.component";
import { ProfilePasswordChangeComponent } from "./views/profile/profile-password-change/profile-password-change.component";
import { ServicesRequesterTypeComponent } from "./views/requester-registration/services-requester-type/services-requester-type.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { AuthGuard } from "./_guards/auth.guard";

const requesterRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./views/login/login.module").then((m) => m.LoginModule),
    data: {
      title: "REQUESTER_LOGIN_PAGE",
    },
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "PAGE_404",
    },
  },
  {
    path: "registration",
    loadChildren: () =>
      import(
        "./views/requester-registration/requester-registration.module"
      ).then((m) => m.RequesterRegistrationModule),
    data: {
      title: "REQUESTER_REGISTRATION_PAGE",
    },
  },
  {
    path: "registration/user-type",
    component: ServicesRequesterTypeComponent,
    data: {
      title: "CHOOSE_REQUESTER_REGISTRATION_TYPE",
    },
  },
  {
    path: "forget-password",
    component: ForgotPasswordComponent,
    data: {
      title: "FORGOT_PASSWORD_PAGE",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "RESET_PASSWORD",
    },
  },
  {
    path: "otp-verification",
    component: OtpVerificationComponent,
    data: {
      title: "OTP_VERIFICATION",
    },
  },
  {
    path: "terms-and-condition",
    loadChildren: () =>
      import("./views/terms-and-condition/terms-and-condition.module").then(
        (m) => m.TermsAndConditionModule
      ),
    data: {
      title: "TERMS_AND_CONDITION",
    },
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./views/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
    data: {
      title: "PRIVACY_POLICY",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "HOME",
    },
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "trip/view",
        loadChildren: () =>
          import("./views/trip-view/trip-view.module").then(
            (m) => m.TripViewModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "TRIP_DETAILS",
        },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/requester-dashboard/requester-dashboard.module").then(
            (m) => m.RequesterDashboardModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "DASHBOARD",
        },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "my-contracts",
        loadChildren: () =>
          import("./views/my-contracts/my-contracts.module").then(
            (m) => m.MyContractsModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "order-history",
        loadChildren: () =>
          import("./views/order-history/order-history.module").then(
            (m) => m.OrderHistoryModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "direct-orders",
        loadChildren: () =>
          import(
            "./views/admin/admin-direct-orders/admin-direct-orders.module"
          ).then((m) => m.AdminDirectOrdersModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "REQUESTER_DIRECT_ORDER",
        },
      },
      {
        path: "notification-list",
        loadChildren: () =>
          import("./views/notification-list/notification-list.module").then(
            (m) => m.NotificationModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "NOTIFICATION_PAGE",
        },
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("./views/contact-us/contact-us.module").then(
            (m) => m.ContactUsModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./views/chat/chat.module").then((m) => m.ChatModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "CHAT",
        },
      },
      {
        path: "special-orders",
        loadChildren: () =>
          import("./views/special-orders/special-orders.module").then(
            (m) => m.SpecialOrdersModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "offer-orders",
        loadChildren: () =>
          import(
            "./views/requester-offer-orders/requester-offer-orders.module"
          ).then((m) => m.RequesterOfferOrdersModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "offers-list",
        loadChildren: () =>
          import("./views/requester-offers/requester-offers.module").then(
            (m) => m.RequesterOffersModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "REQUESTER_OFFERS",
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
    pathMatch: "full",
  },
];

const providerRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./views/login/login.module").then((m) => m.LoginModule),
    data: {
      title: "PROVIDER_LOGIN_PAGE",
    },
  },
  {
    path: "registration",
    loadChildren: () =>
      import("./views/provider-registration/provider-registration.module").then(
        (m) => m.ProviderRegistration
      ),
    data: {
      title: "Provider Registration Page",
    },
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },

  {
    path: "terms-and-condition",
    loadChildren: () =>
      import("./views/terms-and-condition/terms-and-condition.module").then(
        (m) => m.TermsAndConditionModule
      ),
    data: {
      title: "TERMS_AND_CONDITION",
    },
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./views/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
    data: {
      title: "PRIVACY_POLICY",
    },
  },
  {
    path: "forget-password",
    component: ForgotPasswordComponent,
    data: {
      title: "Forgot Password Page",
    },
  },
  {
    path: "otp-verification",
    component: OtpVerificationComponent,
    data: {
      title: "OTP Verification",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "HOME",
    },
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "trip/view",
        loadChildren: () =>
          import("./views/trip-view/trip-view.module").then(
            (m) => m.TripViewModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "TRIP_DETAILS",
        },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "DASHBOARD",
        },
      },
      {
        path: "order-history",
        component: OrderHistoryComponent,
        data: {
          title: "ORDER_HISTORY",
        },
      },
      {
        path: "manage-vehicle",
        loadChildren: () =>
          import("./views/truck/truck.module").then((m) => m.TruckModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "manage-driver",
        loadChildren: () =>
          import("./views/driver/driver.module").then((m) => m.DriverModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "special-orders",
        loadChildren: () =>
          import("./views/special-orders/special-orders.module").then(
            (m) => m.SpecialOrdersModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "order-history",
        loadChildren: () =>
          import("./views/order-history/order-history.module").then(
            (m) => m.OrderHistoryModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "offer-orders",
        loadChildren: () =>
          import("./views/offer-orders/offer-orders.module").then(
            (m) => m.OfferOrdersModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "contracts",
        loadChildren: () =>
          import("./views/contracts/contracts.module").then(
            (m) => m.ContractsModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "direct-orders",
        loadChildren: () =>
          import(
            "./views/admin/admin-direct-orders/admin-direct-orders.module"
          ).then((m) => m.AdminDirectOrdersModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "PROVIDER_DIRECT_ORDER",
        },
      },
      {
        path: "notification-list",
        loadChildren: () =>
          import("./views/notification-list/notification-list.module").then(
            (m) => m.NotificationModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "NOTIFICATION_PAGE",
        },
      },
      {
        path: "direct-orders/pricing",
        loadChildren: () =>
          import("./views/provider-service-pricing/pricing.module").then(
            (m) => m.ProviderPriceModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "PROVIDER_PRICING",
        },
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("./views/contact-us/contact-us.module").then(
            (m) => m.ContactUsModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./views/chat/chat.module").then((m) => m.ChatModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "CHAT",
        },
      },
      {
        path: "offers",
        loadChildren: () =>
          import("./views/providers-offers/offers.module").then(
            (m) => m.OfferListModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "PROVIDER_OFFERS",
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
    pathMatch: "full",
  },
];

const adminRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./views/admin/admin.module").then((m) => m.AdminModule),
    data: {
      title: "ADMIN_LOGIN",
    },
  },
  {
    path: "terms-and-condition",
    loadChildren: () =>
      import("./views/terms-and-condition/terms-and-condition.module").then(
        (m) => m.TermsAndConditionModule
      ),
    data: {
      title: "TERMS_AND_CONDITION",
    },
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./views/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
    data: {
      title: "PRIVACY_POLICY",
    },
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "PAGE_404",
    },
  },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "forget-password",
    component: ForgotPasswordComponent,
    data: {
      title: "FORGOT_PASSWORD_PAGE",
    },
  },
  {
    path: "otp-verification",
    component: OtpVerificationComponent,
    data: {
      title: "OTP Verification",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password",
    },
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "ADMIN_HOME",
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/admin/admin-dashboard/admin-dashboard.module").then(
            (m) => m.AdminDashboardModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_DASHBOARD",
        },
      },
      {
        path: "service-requester",
        loadChildren: () =>
          import(
            "./views/admin/admin-service-requester/admin-service-requester.module"
          ).then((m) => m.AdminServiceRequesterModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_SR",
        },
      },
      {
        path: "special-orders",
        loadChildren: () =>
          import(
            "./views/admin/admin-special-orders/admin-special-orders.module"
          ).then((m) => m.AdminSpecialOrdersModule),
        canActivate: [AuthGuard],
        data: {
          title: "ADMIN_SPECIAL_ORDERS",
          permission: "view_special_orders",
        },
      },
      {
        path: "service-provider",
        loadChildren: () =>
          import(
            "./views/admin/admin-service-provider/admin-service-provider.module"
          ).then((m) => m.AdminServiceProviderModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_SP",
        },
      },
      {
        path: "driver-list",
        loadChildren: () =>
          import("./views/admin/admin-driver/admin-driver.module").then(
            (m) => m.AdminDriverModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_DRIVER_LIST",
        },
      },
      {
        path: "price-items",
        loadChildren: () =>
          import(
            "./views/admin/admin-price-items/admin-price-items.module"
          ).then((m) => m.AdminPriceItemsModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_PRICE_OPTIONS",
        },
      },
      {
        path: "offers-orders",
        loadChildren: () =>
          import("./views/admin/admin-offers/admin-offers.module").then(
            (m) => m.AdminOffersModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_OFFERS",
        },
      },
      {
        path: "admin-manager",
        loadChildren: () =>
          import("./views/admin/admin-manager/admin-manager.module").then(
            (m) => m.AdminManagerModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_MANAGER",
        },
      },
      {
        path: "direct-orders",
        loadChildren: () =>
          import(
            "./views/admin/admin-direct-orders/admin-direct-orders.module"
          ).then((m) => m.AdminDirectOrdersModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_DIRECT_ORDERS",
        },
      },
      {
        path: "manage-vehicle",
        loadChildren: () =>
          import("./views/admin/admin-vehicle/admin-vehicle.module").then(
            (m) => m.AdminVehicleModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_VEHICLE_LIST",
        },
      },
      {
        path: "manage-provider-pricing",
        loadChildren: () =>
          import(
            "./views/admin/admin-price-items/admin-price-items.module"
          ).then((m) => m.AdminPriceItemsModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_PROVIDER_PRICING",
          permission: "show_price_option_sp",
        },
      },
      {
        path: "manage-requester-pricing",
        loadChildren: () =>
          import(
            "./views/admin/admin-price-items/admin-price-items.module"
          ).then((m) => m.AdminPriceItemsModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_REQUESTER_PRICING",
          permission: "show_price_option_sr",
        },
      },
      {
        path: "order-contracts",
        loadChildren: () =>
          import(
            "./views/admin/admin-contracts/admin-sr-contracts.module"
          ).then((m) => m.AdminSrContractsModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADMIN_CONTRACTS",
          permission: "show_sr_contract_order",
        },
      },
      {
        path: "admin-settings",
        loadChildren: () =>
          import("./views/admin/admin-settings/admin-settings.module").then(
            (m) => m.AdminSettingsModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "Admin Settings",
        },
      },
      {
        path: "support-messages",
        loadChildren: () =>
          import(
            "./views/admin/admin-support-messages/admin-support-messages.module"
          ).then((m) => m.AdminSupportMessagesModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "Admin Support Messages",
          permission: "show_support_message",
        },
      },
      {
        path: "profile",
        canActivateChild: [AuthGuard],
        component: AdminManagerProfileComponent,
        data: {
          title: "ADMIN_PROFILE",
        },
      },
      {
        path: "profile/password-change",
        canActivateChild: [AuthGuard],
        component: ProfilePasswordChangeComponent,
        data: {
          title: "ADMIN_PROFILE",
        },
      },
      {
        path: "support-messages",
        loadChildren: () =>
          import(
            "./views/admin/admin-support-messages/admin-support-messages.module"
          ).then((m) => m.AdminSupportMessagesModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "Admin Support Messages",
        },
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./views/admin/admin-chat/admin-chat.module").then(
            (m) => m.AdminChatModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "Admin Chat",
        },
      },
      {
        path: "notification-list",
        loadChildren: () =>
          import("./views/notification-list/notification-list.module").then(
            (m) => m.NotificationModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "NOTIFICATION_PAGE",
        },
      },
      {
        path: "support",
        loadChildren: () =>
          import("./views/support/support.module").then((m) => m.SupportModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "SUPPORT",
        },
      },
      {
        path: "shahen-contract-orders",
        loadChildren: () =>
          import("./views/admin/admin-contracts/admin-contracts.module").then(
            (m) => m.AdminContractsModule
          ),
        canActivateChild: [AuthGuard],
        data: {
          title: "SHAHEN_CONTRACT_ORDERS",
          permission: "show_sh_contract_order",
        },
      },
      {
        path: "city-master",
        loadChildren: () =>
          import(
            "./views/admin/admin-city-master/admin-city-master.module"
          ).then((m) => m.AdminCityMasterModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "CITY_MASTER",
          permission: "view_location_master",
        },
      },
      {
        path: "master-trip",
        loadChildren: () =>
          import(
            "./views/admin/admin-master-trip/admin-master-trip.module"
          ).then((m) => m.AdminMasterTripModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "MASTER_TRIP",
          permission: "view_trip",
        },
      },
      {
        path: "additional-charges",
        loadChildren: () =>
          import(
            "./views/admin/additionalcharges-list/additionalcharges-list.module"
          ).then((m) => m.AdditionalChargesModule),
        canActivateChild: [AuthGuard],
        data: {
          title: "ADDITIONAL_CHARGES",
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
    pathMatch: "full",
  },
];

const routes: Routes = [
  {
    path: "payment-redirect",
    loadChildren: () =>
      import("./views/payment-redirect/payment-redirect.module").then(
        (m) => m.PaymentRedirectModule
      ),
    data: {
      title: "PAYMENT_REDIRECT",
    },
  },
  {
    path: "payment-status",
    component: PaymentCommonStatusComponent,
  },
  {
    path: "payment-intercept",
    component: PaymentInterceptComponent,
  },
  {
    path: "",
    redirectTo: "provider",
    pathMatch: "full",
  },
  {
    path: "requester",
    children: requesterRoutes,
  },
  {
    path: "provider",
    children: providerRoutes,
  },
  {
    path: "admin",
    children: adminRoutes,
  },

  {
    path: "403",
    component: P403Component,
    data: {
      title: "Page 403",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./views/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
    data: {
      title: "PRIVACY_POLICY",
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
