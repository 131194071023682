<div class="requester-order-info">
  <div
    class="toater"
    *ngIf="
      this.orderDetails?.sr_status === this.orderStatus?.WAITING_FOR_APPROVAL &&
      this.orderDetails?.vehicle_count > 0 &&
      this.orderDetails?.vehicle_count !== this.orderDetails?.quantity
    "
  >
    <div class="toater-left">
      <div class="icon-area">
        <i class="icon-warning"></i>
      </div>
      <div class="text-area">
        <span class="notification-top"
          >{{ "SELECTED_SERVICE_PROVIDER_CAN_ONLY_PROVIDE" | translate }}
          {{ this.orderDetails?.vehicle_count }}
          {{
            (this.orderDetails?.vehicle_count === 1
              ? "TRUCK_NAME"
              : "TRUCKS_NAME"
            ) | translate
          }}
          {{ "OUT_OF" | translate }}
          {{ this.orderDetails?.quantity }}
          {{ "TRUCKS_NAME" | translate }}</span
        >
      </div>
    </div>
    <div class="toater-right">
      <a
        (click)="openTruckModal(truckAssignment)"
        class="btn btn-secondary"
        id="openAcceptTrucks"
        >{{ "ACCEPT" | translate }} {{ this.orderDetails?.vehicle_count }}
        {{
          (this.orderDetails?.vehicle_count === 1
            ? "TRUCK_NAME"
            : "TRUCKS_NAME"
          ) | translate
        }}
      </a>
      <a
        *ngIf="this.typeOfOrder !== this.orderType.offer_order"
        (click)="selectProviderConfirmation(selectNewProvider)"
        class="btn btn-primary"
        id="openSelectProvider"
        >{{ "SELECT_NEW_PROVIDER" | translate }}</a
      >
    </div>
  </div>
  <h3>{{ "ORDER_INFO" | translate }}</h3>
  <div
    *ngIf="
      this.orderDetails?.status === this.orderStatus?.CANCELLED &&
      this.orderDetails?.order_cancel_by
    "
    class="cancel-order-box"
  >
    <!-- <div class="cancel-order-title d-flex align-center">
      <span class="heading-left"
        >{{ "ORDER_CANCEL_BY" | translate }}&nbsp;</span
      >
      <span class="heading-right">
        {{
          this.orderDetails?.shahen_order === 1 ||
          (this.orderDetails?.order_cancel_by?.user?.roles)[0]?.name ===
            this.adminTypes.SUPER_ADMIN_ROLE
            ? this.shahenName.SHAHEN_EXPRESS
            : this.orderDetails?.order_cancel_by?.user?.name +
              "(" +
              getCancelOrderAuthorDetails(this.orderDetails) +
              ")"
        }}&nbsp; {{ "AT" | translate }}&nbsp;
        {{
          this.orderDetails?.order_cancel_by?.cancelled_at
            | customDate: momentDateTimeFormat
        }}
      </span>
    </div>
    <p class="cancel-reason">{{ this.orderDetails?.refused_comment }}</p> -->

    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <div class="cancellation-reason-text">
            <strong class="reason-title"
              >{{ "CANCELLATION_REASON" | translate }}:</strong
            >
            <span class="cancelled-reason">{{
              this.orderDetails?.refused_comment
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <span class="reason-text">
            <strong class="reason-title"
              >{{ "ORDER_CANCEL_BY" | translate }}:</strong
            >
            {{
              this.orderDetails?.shahen_order === 1 ||
              (this.orderDetails?.order_cancel_by?.user?.roles)[0]?.name ===
                this.adminTypes.SUPER_ADMIN_ROLE
                ? this.shahenName.SHAHEN_EXPRESS
                : this.orderDetails?.order_cancel_by?.user?.name +
                  "(" +
                  getCancelOrderAuthorDetails(this.orderDetails) +
                  ")"
            }}&nbsp; {{ "AT" | translate }}&nbsp;
            {{
              this.orderDetails?.order_cancel_by?.cancelled_at
                | customDate: momentDateTimeFormat
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="border-box">
    <div class="order-data space-between">
      <div class="status-box">
        <div
          class="state"
          [ngClass]="{
            'status-approval-yellow':
              this.orderDetails?.sr_status ===
              this.orderStatus?.WAITING_FOR_APPROVAL,
            'status-approval-blue':
              this.orderDetails?.sr_status ===
                this.orderStatus?.SHIPMENT_IN_PROGRESS ||
              this.orderDetails?.sr_status ===
                this.orderStatus?.SHIPMENT_ON_THE_WAY
          }"
        >
          <div class="icon-area">
            <i
              *ngIf="
                this.orderDetails?.sr_status !==
                  this.orderStatus?.WAITING_FOR_APPROVAL &&
                this.orderDetails?.sr_status !==
                  this.orderStatus?.WAITING_FOR_APPROVAL_SR &&
                this.orderDetails?.sr_status !== this.orderStatus?.NEW_CONTRACT
              "
              [ngClass]="{
                fill:
                  this.orderDetails?.sr_status ===
                    this.orderStatus?.SHIPMENT_IN_PROGRESS ||
                  this.orderDetails?.sr_status ===
                    this.orderStatus?.SHIPMENT_ON_THE_WAY,
                'icon-mark':
                  this.orderDetails?.sr_status !==
                    this.orderStatus?.SHIPMENT_IN_PROGRESS &&
                  this.orderDetails?.sr_status !==
                    this.orderStatus?.SHIPMENT_ON_THE_WAY
              }"
            ></i>
          </div>
          <span [ngStyle]="{ color: orderDetails?.status_color?.hex }">
            {{ this.orderDetails?.orderStatus }}
          </span>
        </div>
        <label class="floating-label">{{ "STATUS" | translate }}</label>
      </div>
      <div class="shipment-information">
        <div class="shipment-box">
          <div
            class="circular-progress"
            [ngClass]="storeProgressBarClass"
            [currentTrips]="this.orderDetails?.tripStatues?.at_loading_location"
            appCircularProgress
            [totalTrips]="this.orderDetails?.quantity"
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.at_loading_location }}/{{
                this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "SHIPMENT_IN_PROGRESS" | translate }}
          </span>
        </div>
        <div class="shipment-box">
          <div
            [ngClass]="storeProgressBarClass"
            [totalTrips]="this.orderDetails?.quantity"
            appCircularProgress
            [currentTrips]="this.orderDetails?.tripStatues?.on_the_way_trip"
            class="circular-progress"
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.on_the_way_trip }}/{{
                this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "SHIPMENT_ON_THE_WAY" | translate }}
          </span>
        </div>
        <div class="shipment-box">
          <div
            class="circular-progress"
            [ngClass]="storeProgressBarClass"
            appCircularProgress
            [totalTrips]="this.orderDetails?.quantity"
            [currentTrips]="
              this.orderDetails?.tripStatues?.at_unloading_location
            "
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.at_unloading_location }}/{{
                this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "DELIVERED" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="detail-area">
      <div class="data">
        <div class="detail-data">
          <span class="title">{{ "PROVIDER_NAME" | translate }}</span>
          <span
            *ngIf="
              this.orderType.special_order !==
              this.orderType[this.orderDetails?.section]
            "
            class="detail"
            title="{{
              this.orderDetails?.provider?.company_name
                ? this.orderDetails?.provider?.company_name
                : this.orderDetails?.shahen_order === 1
                ? orderTypes.SHAHEN
                : 'N/A'
            }}"
            >{{
              this.orderDetails?.provider?.company_name
                ? this.orderDetails?.provider?.company_name
                : this.orderDetails?.shahen_order === 1
                ? orderTypes.SHAHEN
                : "N/A"
            }}</span
          >
          <span
            *ngIf="
              this.orderType.special_order ===
              this.orderType[this.orderDetails?.section]
            "
            class="detail"
            title="{{ orderTypes.SHAHEN }}"
            >{{ orderTypes.SHAHEN }}</span
          >
        </div>
        <div class="detail-data">
          <span class="title">{{ "ORDER_DATE" | translate }}</span>
          <span class="detail">
            {{
              this.orderDetails?.created_at_format
                | customDate: dateFormatListing
            }}
          </span>
        </div>
        <div class="detail-data">
          <span class="title">{{ "ORDER_TYPE" | translate }}</span>
          <span class="detail">
            {{
              this.orderDetails?.offer_id
                ? this.orderType.offer_order
                : this.orderType[this.orderDetails?.section]
            }}
          </span>
        </div>
        <div
          class="detail-data"
          *ngIf="
            this.orderDetails?.section === this.orderTypes.MEMBERSHIP ||
            this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
          "
        >
          <span class="title">{{ "PRICE(SR)" | translate }}</span>
          <span class="detail">
            {{
              this.orderDetails?.section === this.orderTypes.MEMBERSHIP
                ? this.orderDetails?.order_prices?.price +
                  " x " +
                  this.orderDetails?.quantity +
                  " = " +
                  (this.orderDetails?.order_prices?.sub_total).toFixed(0) +
                  " " +
                  this.measurementUnits.SAR
                : this.orderDetails?.sr_price_special_order +
                  " x " +
                  this.orderDetails?.quantity +
                  " = " +
                  (this.orderDetails?.sr_price_without_vat_special_order).toFixed(
                    0
                  ) +
                  " " +
                  this.measurementUnits.SAR
            }}
          </span>
        </div>
        <div
          class="detail-data"
          *ngIf="
            this.orderTypes.OFFERS === this.orderDetails?.section ||
            this.orderTypes.ORDER === this.orderDetails?.section
          "
        >
          <span class="title">{{ "PRICE" | translate }}</span>
          <span class="detail">{{
            this.orderDetails?.order_prices?.sp_price +
              " X " +
              this.orderDetails?.order_prices?.quantity +
              " = " +
              (this.orderDetails?.order_prices?.sub_total).toFixed(0) +
              " " +
              this.measurementUnits.SAR
          }}</span>
        </div>
        <div
          class="detail-data"
          *ngIf="
            this.orderDetails?.section === this.orderTypes.MEMBERSHIP ||
            this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
          "
        >
          <span class="title"
            >{{ "TOTAL_PRICE" | translate }} ({{ "INCL_VAT" | translate }}
            <b>{{ this.orderDetails?.vat_percentage }}</b
            >%)</span
          >
          <span class="detail">
            {{
              this.orderDetails?.order_prices?.total_price +
                " " +
                this.measurementUnits.SAR
            }}
          </span>
        </div>
        <div
          class="detail-data"
          *ngIf="
            this.orderTypes.ORDER === this.orderDetails?.section ||
            this.orderTypes.OFFERS === this.orderDetails?.section
          "
        >
          <span class="title"
            >{{ "TOTAL_PRICE" | translate }} ({{ "INCL_VAT" | translate }}
            <b>{{ this.orderDetails?.vat_percentage }}</b
            >%)</span
          >
          <span class="detail">
            {{
              this.orderDetails?.providers.length > 0 ||
              this.orderDetails?.provider_id
                ? (this.orderDetails?.total_price).toFixed(0) +
                  " " +
                  this.measurementUnits.SAR
                : " - "
            }}
          </span>
        </div>
      </div>
      <div class="btn-area">
        <a
          (click)="openModal()"
          class="btn bordered-btn btn-medium btn-medium-small"
          *ngIf="
            this.orderDetails?.bayan_trips_count > 0 &&
            this.orderDetails?.sr_status !== this.orderStatus?.CANCELLED
          "
        >
          {{ "VIEW_TRIPS_DETAILS" | translate }}
        </a>
        <a
          (click)="downloadInvoice()"
          *ngIf="
            (this.orderDetails.section === this.orderTypes.ORDER ||
            this.orderDetails.section === this.orderTypes.OFFERS
              ? (this.orderDetails?.paid === 1 &&
                  this.orderDetails?.sr_status !==
                    this.orderStatus?.CANCELLED) ||
                this.orderDetails?.sr_status ===
                  this.orderStatus?.SR_INVOICE_SENT
              : false) ||
            this.orderDetails?.sr_status ===
              this.orderStatus?.INVOICE_RECEIVED ||
            this.orderDetails?.sr_status === this.orderStatus?.INVOICE_PAID ||
            this.orderDetails?.sr_status === this.orderStatus?.PAYMENT_PENDING
          "
          target="_blank"
          class="btn btn-secondary btn-small"
        >
          <i class="icon-Invoice"></i>
          {{ "VIEW_INVOICES" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<ng-template #truckAssignment>
  <app-truck-assigment-popup
    [(truck_quantity)]="truck_quantity"
    [(vehicle_count)]="vehicle_count"
    (getVehicleCount)="getTruckAssignment($event)"
    (acceptOrder)="acceptOrder()"
    (close)="decline()"
  >
  </app-truck-assigment-popup>
</ng-template>
<ng-template #selectNewProvider>
  <app-select-new-provider-popup
    (accept)="selectNewProviderConfirmation()"
    (close)="closeSelectProviderModal()"
  >
  </app-select-new-provider-popup>
</ng-template>
