import { Validators } from "@angular/forms";

const emailRegx = /^[^\s@]+@[^\s@+]+\.[^\s@]{2,320}$/;
const passwordLengthRegex = /^.{8,30}$/;
const lettersOnlyRegex = /^[A-Za-z\s]+$/;
const numbersOnlyRegex = /^\d+$/;
const nationalIdRegex = /^\d{10}$/;
const absherMobileNumberRegex = /^(05|5)[503649187](\d{7})$/;
const vatNumberRegex = /^\d{15}$/;
const maxNumberFleet = /^(?:[1-4]?\d{1,3}|5000)$/;
const maxNumberFleetLeadingZeros = /^0*(5000|[1-9]|[1-9]\d|[1-9]\d\d|[1-4]\d\d\d\d{0,2})$/;
const passCapitalOrSymbolRegex = /^(?![A-Z!@#$%^&*()\-_=+{}[\]|\\;:'",<.>/?]).*$/;
const passwordRegx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*\d)(?=.*[@!$%^&#])[A-Za-z\d@!$%^&#]{8,30}$/;
const addArabicCheckRegex = /^(?=.*[\u0600-\u06FF])(?![A-Za-z]).*$/;
const phoneNumberRegex = /^\d{1,10}$/;
const platelengthRegex = /^\d{4}$/;
const maxTruckQuantity = /^(?:[1-9]|10)$/;
const valueNotNulllCheck = /^(?!0$)\d+$/;
const weightValidation = /^[0-9.-]*$/;
const driverIssueNumberRegex = /^[1-9]\d?$/;
const maxOfferTruckQuantity = /^(?:[1-9]|[1-9]\d)$/;
const timeZoneOffSetMatch = /([+-])(\d{2})(\d{2})/;
const appApplettersonlyRegex = /^[a-zA-Z\s]*$/;
const appApplettersDetectRegex = /[^a-zA-Z\s]+/g;
const threeDigitMaxQuantity = /^(?:100|[1-9]\d|[1-9])$/;
const digitMatchRegex = /\d/;
const additionalChargesValuesRegex = "^(0|(-?[1-9][0-9]*))$";

export const emailValidator = Validators.pattern(emailRegx);
export const loginPasswordValidator = Validators.pattern(passwordLengthRegex);
export const numberOnlyRegex =
  "\\s*((\\d+(\\.\\d{0,{decimals}})?)|((\\d*(\\.\\d{1,{decimals}}))))\\s*";

const Validator = {
  emailValidator,
  loginPasswordValidator,
};

export const CommonRegx = {
  emailRegx,
  passwordLengthRegex,
  lettersOnlyRegex,
  numbersOnlyRegex,
  nationalIdRegex,
  absherMobileNumberRegex,
  vatNumberRegex,
  maxNumberFleet,
  maxNumberFleetLeadingZeros,
  passCapitalOrSymbolRegex,
  addArabicCheckRegex,
  phoneNumberRegex,
  platelengthRegex,
  passwordRegx,
  maxTruckQuantity,
  weightValidation,
  valueNotNulllCheck,
  driverIssueNumberRegex,
  maxOfferTruckQuantity,
  timeZoneOffSetMatch,
  appApplettersonlyRegex,
  appApplettersDetectRegex,
  threeDigitMaxQuantity,
  digitMatchRegex,
  additionalChargesValuesRegex,
};

export default Validator;
