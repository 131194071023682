import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONFIG } from "@config/app-config";
import { ISuccessResponse } from "@model/successResponse";
import {
  IAddtiionalChargesListResponse,
  IChargesDrpData,
  ITripAdditionalChargesPayload,
} from "@model/tripAdditionalCharges";
import { UserRole } from "@utils/enum-const";
import { map } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class AdditionalChargesService {
  userType = "";
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Save additional charges
   */
  saveAdditionalCharges(
    additionalChargesPayload: ITripAdditionalChargesPayload
  ) {
    const saveAdditionalChargeURL =
      this.userType === UserRole.SUPER_ADMIN_USER
        ? CONFIG.saveAdminAdditionalCharges
        : CONFIG.saveProviderAdditionalCharges;

    return this.http
      .post<ISuccessResponse>(saveAdditionalChargeURL, additionalChargesPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle get additional charges
   * @returns IChargesDrpData
   * @author prahsank.shah@brainvire.com
   */
  getAdminAdditionalChargesTypes() {
    const getAdditionalChargeURL =
      this.userType === UserRole.SUPER_ADMIN_USER
        ? CONFIG.getAdminAdditionalChargesTypes
        : CONFIG.getProviderAdditionalChargesTypes;

    return this.http.get<IChargesDrpData>(getAdditionalChargeURL).pipe(
      map((response: IChargesDrpData) => {
        return response;
      })
    );
  }

  /**
   * Trip View Additional Charges List
   */
  additionalChargesTripDetails(id: number) {
    let getTripViewAdditionalChargesUrl = "";
    switch (this.userType) {
      case UserRole.SUPER_ADMIN_USER || UserRole.ADMIN_USER:
        getTripViewAdditionalChargesUrl =
          CONFIG.adminAdditionalChargesTripViewList;
        break;

      case UserRole.SERVICE_PROVIDER:
        getTripViewAdditionalChargesUrl =
          CONFIG.providerAdditionalChargesTripViewList;
        break;

      case UserRole.SERVICE_REQUESTER:
        getTripViewAdditionalChargesUrl =
          CONFIG.requesterAdditionalChargesTripViewList;
        break;
    }
    return this.http
      .get<IAddtiionalChargesListResponse>(getTripViewAdditionalChargesUrl + id)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Approve/Reject Additional Charge
   */
  approveRejectAdditionalCharges(
    id: number,
    is_approve: number,
    reject_reason?: string
  ) {
    const approveRejectPayload = {
      is_approve,
      ...(is_approve === 2 && { reject_reason }),
    };
    let getTripViewApproveRejectUrl = "";
    switch (this.userType) {
      case UserRole.SUPER_ADMIN_USER || UserRole.ADMIN_USER:
        getTripViewApproveRejectUrl = CONFIG.adminApproveRejectUrl;
        break;

      case UserRole.SERVICE_REQUESTER:
        getTripViewApproveRejectUrl = CONFIG.requesterApproveRejectUrl;
        break;
    }
    return this.http
      .post<ISuccessResponse>(
        getTripViewApproveRejectUrl + id,
        approveRejectPayload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Edit Additional Charge
   */
  editAdditionalCharges(id: number, sp_price: string, sr_price: string) {
    const editPayload = {
      sp_price,
      sr_price,
    };
    let getEditAditionalChargeUrl = "";
    switch (this.userType) {
      case UserRole.SUPER_ADMIN_USER:
        getEditAditionalChargeUrl = CONFIG.adminEditAdditionalChargeUrl;
        break;

      case UserRole.ADMIN_USER:
        getEditAditionalChargeUrl = CONFIG.adminEditAdditionalChargeUrl;
        break;

      case UserRole.SERVICE_REQUESTER:
        getEditAditionalChargeUrl = CONFIG.requesterEditAdditionalChargeUrl;
        break;

      case UserRole.SERVICE_PROVIDER:
        getEditAditionalChargeUrl = CONFIG.providerEditAdditionalChargeUrl;
        break;
    }
    return this.http
      .post<ISuccessResponse>(getEditAditionalChargeUrl + id, editPayload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /*
   * API call for pending additional charges
   * @param payload
   * @returns
   */
  getPendingAdditionalCharges(payload: any) {
    const pendingAdditionalChargesURL =
      this.userType === UserRole.SUPER_ADMIN_USER
        ? CONFIG.fetchAdminPendingAdditionalCharges
        : CONFIG.fetchProviderPendingAdditionalCharges;
    return this.http.post<any>(pendingAdditionalChargesURL, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /*
   * API call for downloading additional charges
   * @param payload
   * @returns
   */
  downloadAdditionalCharges(payload: any) {
    return this.http.post<any>(CONFIG.additionalChargesDownload, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /*
   * API call for deleting additional charges
   * @param payload
   * @returns
   */
  deleteAdditionalCharges(id: number) {
    let getDeleteAditionalChargeUrl = "";
    switch (this.userType) {
      case UserRole.SUPER_ADMIN_USER:
        getDeleteAditionalChargeUrl = CONFIG.adminAdditionalChargesDelete;
        break;

      case UserRole.ADMIN_USER:
        getDeleteAditionalChargeUrl = CONFIG.adminAdditionalChargesDelete;
        break;

      case UserRole.SERVICE_REQUESTER:
        getDeleteAditionalChargeUrl = CONFIG.providerAdditionalChargesDelete;
        break;

      case UserRole.SERVICE_PROVIDER:
        getDeleteAditionalChargeUrl = CONFIG.requesterAdditionalChargesDelete;
        break;
    }
    return this.http.get<any>(getDeleteAditionalChargeUrl + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
