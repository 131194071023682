import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { first } from "rxjs/operators";
import { AllPermissionsList } from "../../../model/adminManager";
import { IErrorResponse } from "../../../model/errorResponse";
import {
  contractOrderPermission,
  returnContractOrderPermission,
} from "../../../utils/enum-const";
import { BaseComponent } from "../../../_components/base.component";
import { AutoUnsubscribe } from "../../../_decorator/autounsubscribe";
import { AdminMangerService } from "../../../_services/admin-manger.service";
import { SomeUniqueIdentifier } from "@model/adminChat";

@Component({
  selector: "app-admin-permissions",
  templateUrl: "./admin-permissions.component.html",
  styleUrls: ["./admin-permissions.component.scss"],
})
@AutoUnsubscribe()
export class AdminPermissionsComponent extends BaseComponent implements OnInit {
  @Output() dataChange: EventEmitter<Array<number>> = new EventEmitter<
    Array<number>
  >();
  @Input() checkedBoxes: Array<number> = [];

  allCheckBoxes: Array<AllPermissionsList> = [
    { name: "Assign", id: "assign", checked: false, sort: 0 },
    { name: "Change SP", id: "changeSp", checked: false, sort: 1 },
    { name: "Change Status", id: "statusChange", checked: false, sort: 2 },
    { name: "Approve", id: "approve", checked: false, sort: 3 },
    { name: "View", id: "view", checked: false, sort: 4 },
    {
      name: "Price View & Sales Chart",
      id: "viewPrice",
      checked: false,
      sort: 5,
    },
    { name: "Export", id: "export", checked: false, sort: 6 },
    { name: "Add", id: "add", checked: false, sort: 7 },
    { name: "Edit", id: "edit", checked: false, sort: 8 },
    { name: "Delete", id: "delete", checked: false, sort: 9 },
    { name: "Cancel Trip", id: "cancelTrip", checked: false, sort: 10 },
    { name: "Cancel Order", id: "cancelOrder", checked: false, sort: 11 },
  ];
  permissionList: any = [];
  checkedList: Array<number> = [];

  constructor(private permisssionService: AdminMangerService) {
    super();
  }

  /**
   * Load all subadmin permissions
   */
  ngOnInit() {
    this.getAllSubAdminPermissions();
  }

  /**
   * Open permissions modal
   */
  openPermissions(template: TemplateRef<string>) {
    this.checkedList = this.checkedBoxes ? this.checkedBoxes : [];
    this.openModal(template);
    this.allCheckBoxes.forEach((_: any, i: any) => {
      this.allChecked(i);
    });
  }

  /**
   * Get all sub admin permissions
   */
  public getAllSubAdminPermissions() {
    this.spinner.showLoader();
    this.permisssionService
      .fetchSubAdminPermissions()
      .pipe(first())
      .subscribe(
        (resp: any) => {
          if (resp.status === true) this.loadingIndicator = false;
          this.permissionList = Object.entries(
            resp.data[0]
          ).map(([key, value]) => [key, [value]]);

          this.spinner.hideLoader();
        },
        (error: IErrorResponse) => {
          this.errorHandler(this.toastr, this.translateService, error);
          this.loadingIndicator = false;
          this.spinner.hideLoader();
        }
      );
  }

  /**
   * Get all checkbox selected
   */
  allCheckBoxChange(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.permissionList.forEach((item: any) => {
        if (
          item[1][0][index].is_checkbox === 1 &&
          !this.checkedList.includes(item[1][0][index].id)
        ) {
          this.checkedList.push(item[1][0][index].id);
        }
      });
    }
    if (!target.checked) {
      const newList = this.permissionList.map((item: any) => {
        if (item[1][0][index].is_checkbox === 1) {
          return item[1][0][index].id;
        }
      });
      const filterOut = this.checkedList.filter(
        (item) => !newList.includes(item)
      );
      this.checkedList = filterOut;
    }
    this.allChecked(index);
  }

  /**
   * Handle open permission modal
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-xxl" });
  }

  /**
   * string transform label display
   */
  transformString(input: string): string {
    if (
      input === contractOrderPermission.SH_CONTRACT_ORDER ||
      input === contractOrderPermission.SR_CONTRACT_ORDER
    ) {
      const data =
        input === contractOrderPermission.SH_CONTRACT_ORDER
          ? returnContractOrderPermission.SH_CONTRACT_ORDER
          : returnContractOrderPermission.SR_CONTRACT_ORDER;
      return data;
    } else if (input.includes("_")) {
      const words = input.split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return capitalizedWords.join(" ");
    } else {
      return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    }
  }

  /**
   * Handle checkbox check
   */
  checkChecked(event: Event, id: number, index: number) {
    const target = event.target as HTMLInputElement;
    if (target.checked === true) {
      if (!this.checkedList.includes(id)) {
        this.checkedList.push(id);
      }
    } else {
      this.checkedList = this.checkedList.filter((el) => el !== id);
    }
    this.allChecked(index);
  }

  /**
   * Handle all checkbox checked
   */
  allChecked(index: number) {
    const newList = this.permissionList.reduce((result: any, item: any) => {
      if (item[1][0][index].is_checkbox === 1) {
        result.push(item[1][0][index].id);
      }
      return result;
    }, []);
    if (!newList.every((item: any) => this.checkedList.includes(item))) {
      const checkBox = this.allCheckBoxes?.[index];
      if (checkBox !== undefined) {
        checkBox.checked = false;
      }
    }
    if (newList.every((item: any) => this.checkedList.includes(item))) {
      const checkBox = this.allCheckBoxes?.[index];
      if (checkBox !== undefined) {
        checkBox.checked = true;
      }
    }
  }

  /**
   * Permission data send to parent compoent action bind
   */
  sendDataToParent() {
    this.dataChange.emit(this.checkedList);
    this.decline();
  }

  /**
   * Close modal popup permission
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Decline modal
   */
  handleClose() {
    this.decline();
  }

  /**
   * TrackBy function to improve ngFor performance
   */
  trackByFn(item: SomeUniqueIdentifier) {
    return item.someUniqueIdentifier;
  }
}
