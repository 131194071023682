<app-language-selection *ngIf="!this.isAdminRoute"> </app-language-selection>
<div class="form-data">
  <app-left-register-panel> </app-left-register-panel>

  <div class="right-section">
    <div class="logo">
      <picture>
        <app-right-register-panel> </app-right-register-panel>
      </picture>
    </div>
    <div class="form-area">
      <h1>{{ "OTP_VERIFICATION" | translate }}</h1>
      <p class="subtitle">{{ "OTP_SEND_VERIFICATION_MAIL" | translate }}</p>
      <div class="forgot-password-form">
        <form
          (ngSubmit)="onOtpVerification(otpVerificationForm)"
          novalidate
          #otpVerificationForm="ngForm"
          InvalidFormScrollNGFormDirective
        >
          <div class="form-group">
            <input
              appSpaceRemove
              type="text"
              [(ngModel)]="model.field1"
              name="field1"
              class="form-control"
              required
              id="field1"
              #field1="ngModel"
              [dir]="isEnglish ? 'ltr' : 'rtl'"
              maxlength="1"
              appNumberOnly
              (input)="moveToNextInput('field2')"
            />
            <input
              appSpaceRemove
              type="text"
              [(ngModel)]="model.field2"
              name="field2"
              class="form-control"
              required
              id="field2"
              [dir]="isEnglish ? 'ltr' : 'rtl'"
              #field2="ngModel"
              maxlength="1"
              appNumberOnly
              (input)="moveToNextInput('field3')"
            />
            <input
              appSpaceRemove
              type="text"
              [(ngModel)]="model.field3"
              name="field3"
              class="form-control"
              required
              id="field3"
              #field3="ngModel"
              maxlength="1"
              appNumberOnly
              [dir]="isEnglish ? 'ltr' : 'rtl'"
              (input)="moveToNextInput('field4')"
            />
            <input
              appSpaceRemove
              type="text"
              [(ngModel)]="model.field4"
              name="field4"
              class="form-control"
              required
              id="field4"
              #field4="ngModel"
              [dir]="isEnglish ? 'ltr' : 'rtl'"
              maxlength="1"
              appNumberOnly
            />
            <div
              class="form-control-feedback text-danger"
              *ngIf="!isAllFieldSubmitted && submitted"
            >
              {{ "ENTER_OTP" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="d-col just-center">
              <button type="submit" class="btn btn-secondary btn-small">
                {{ "VERIFY" | translate }}
              </button>
            </div>
            <a
              type="button"
              (click)="onSendEmail()"
              class="resendLink"
              [ngClass]="{ 'resendLink-disabled': !sendMail }"
              [class.disabled]="!sendMail"
            >
              {{ "RESEND_OTP" | translate }}
              {{ timer > 0 ? "(" + timer + ")" : "" }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
