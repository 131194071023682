import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { IMapProps } from "@model/common";
import { MapService } from "@services/map.service";
import { mapProperties } from "@utils/common";
import { languageTypes, mapErrorIssue } from "@utils/enum-const";
import { first } from "rxjs/operators";

@Component({
  selector: "app-map-details-contract-direct-orders",
  templateUrl: "./map-details-contract-direct-orders.component.html",
  styleUrls: [
    "./map-details-contract-direct-orders.component.scss",
    "../provider-registration/provider-registration.component.scss",
  ],
})
@AutoUnsubscribe()
export class MapDetailsContractDirectOrdersComponent extends BaseComponent
  implements OnInit, OnChanges {
  isMapSelected = false;
  @Input() isMapModalViewHide = false;
  isTyping = false; // Tracks whether the user is typing
  isHovering = false; // Tracks whether the mouse is hovering
  @Input() googleMapId = "googleMap";
  @Input() isHideSearch = false;
  @Input() lat!: number;
  @Input() long!: number;
  @Output() getMapData = new EventEmitter<IMapProps>();
  public map!: google.maps.Map;
  mapDetails!: IMapProps;
  searchLocationLatLong!: google.maps.LatLng;
  public markers: any = [];
  @Input() isArabicAddressShow = false;
  constructor(private mapService: MapService, private zone: NgZone) {
    super();
  }

  ngOnInit(): void {
    // Binding Dynamic ID on Div Element after that intialize map
    // Incase removing timeout id will get null so map data binding issue
    setTimeout(() => {
      this.initMap();
    }, 1500);
  }

  /**
   * Map View
   */
  async ngOnChanges(changes: SimpleChanges) {
    this.spinner.showLoader();
    if (this.isHideSearch) {
      this.initSearchLocationAutoComplete();
    }
    setTimeout(async () => {
      if (changes["lat"]?.currentValue && changes["long"]?.currentValue) {
        this.lat = changes["lat"].currentValue;
        this.long = changes["long"].currentValue;
        if (this.lat && this.long) {
          // this.initMap();
          const latlng = new google.maps.LatLng(this.lat, this.long);
          this.createMarker(latlng);
          this.onLatLngEvent(latlng);
          if (!this.isArabicAddressShow) {
            const detailedAddress = await this.getDetailedAddress(
              this.lat,
              this.long
            );
            if (detailedAddress) {
              this.getMapData.emit({
                lat: this.lat,
                lng: this.long,
                address: detailedAddress.address,
                street_name: detailedAddress?.streetName,
                city_name: detailedAddress?.city,
                country: detailedAddress?.countryName,
                postal_code: detailedAddress?.postalCode,
                district: detailedAddress?.districtName,
                province_name: detailedAddress?.districtName,
              });
            }
          }
        }
        this.isMapSelected = true;
      }
      this.spinner.hideLoader();
    }, 2500);
  }

  /**
   * init map view
   */
  public initMap() {
    const southArabiaCenter = new google.maps.LatLng(24.7136, 46.6753);
    const mapProp: google.maps.MapOptions = {
      ...mapProperties,
      center: southArabiaCenter, // Set the map's initial center to South Arabia
      zoom: 10,
      zoomControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_CENTER,
      },
      mapTypeControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_TOP,
      },
      // For future reference
      // restriction: {
      //   latLngBounds: {
      //     east: mapSothArabicProperties.east,
      //     west: mapSothArabicProperties.west,
      //     north: mapSothArabicProperties.north,
      //     south: mapSothArabicProperties.south,
      //   },
      //   strictBounds: true,
      // },
      disableDefaultUI: true, // This will disable most default UI controls (including keyboard shortcuts)
    };
    const nodeElement = document.getElementById(this.googleMapId);
    if (nodeElement) {
      this.map = new google.maps.Map(
        document.getElementById(this.googleMapId) as HTMLElement,
        mapProp
      );
      google.maps.event.addListener(this.map, "center_changed", () => {
        this.zone?.run(() => {
          this.mapService.checkBounds(this.map);
        });
      });
    }
    google.maps.event.addListener(this.map, "click", async (event) => {
      this.zone?.run(async () => {
        this.lat = event.latLng.lat();
        this.long = event.latLng.lng();
        const latlng = new google.maps.LatLng(this.lat, this.long);
        this.createMarker(latlng);
        const detailedAddress = await this.getDetailedAddress(
          this.lat,
          this.long
        );
        let arabicAddressData: any = null;
        if (this.isArabicAddressShow) {
          arabicAddressData = await this.getDetailedAddress(
            this.lat,
            this.long,
            languageTypes.DEFAULT
          );
        }

        if (detailedAddress) {
          this.getMapData.emit({
            lat: this.lat,
            lng: this.long,
            address: detailedAddress.address,
            street_name: detailedAddress?.streetName,
            city_name: detailedAddress?.city,
            country: detailedAddress?.countryName,
            postal_code: detailedAddress?.postalCode,
            district: detailedAddress?.districtName,
            province_name: detailedAddress?.districtName,
            city_name_ar: arabicAddressData ? arabicAddressData?.city : "",
          });
        }
      });
    });
    this.initSearchLocationAutoComplete();

    if (this.lat && this.long) {
      const latlng = new google.maps.LatLng(this.lat, this.long);
      this.createMarker(latlng);
      this.onLatLngEvent(latlng);
    } else {
      navigator["geolocation"].getCurrentPosition(
        async (position: GeolocationPosition) => {
          const currentPosition = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          if (this.isPlaceInSouthArabia(currentPosition)) {
            this.createMarker(currentPosition);
            this.onLatLngEvent(currentPosition);

            // Get detailed address from API
            const detailedAddress: any = await this.getDetailedAddress(
              currentPosition.lat(),
              currentPosition.lng()
            );
            let arabicAddress: any = null;
            if (this.isArabicAddressShow) {
              arabicAddress = await this.getDetailedAddress(
                currentPosition.lat(),
                currentPosition.lng(),
                languageTypes.DEFAULT
              );
            }
            this.getMapData.emit({
              lat: currentPosition.lat(),
              lng: currentPosition.lng(),
              address: detailedAddress.address,
              city_name: detailedAddress.city_name.long_name,
              street_name: detailedAddress?.streetName,
              country: detailedAddress?.countryName,
              postal_code: detailedAddress?.postalCode,
              district: detailedAddress?.districtName,
              province_name: detailedAddress?.districtName,
              city_name_ar: arabicAddress ? arabicAddress?.city : "",
            });
            this.isMapSelected = true;
          }
        }
      );
    }
  }

  /**
   * Initial Search Location
   */
  initSearchLocationAutoComplete() {
    const that = this;
    const input = document.getElementById(
      "autocomplete_search_" + this.googleMapId
    ) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener("place_changed", async function () {
      // Get place details
      const place = autocomplete.getPlace();
      if (place.geometry?.location) {
        // Ensure that geometry and location are not undefined
        if (that.isPlaceInSouthArabia(place)) {
          that.searchLocationLatLong = new google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );
          that.lat = place.geometry.location.lat();
          that.long = place.geometry.location.lng();
          if (
            that.searchLocationLatLong.lat() &&
            that.searchLocationLatLong.lng()
          ) {
            that.zone.run(async () => {
              that.onLatLngEvent(that.searchLocationLatLong);
              that.createMarker(that.searchLocationLatLong);
              that.isMapSelected = true;

              const detailedAddress = await that.getDetailedAddress(
                that.lat,
                that.long
              );
              let arabicAddressData: any = null;
              if (that.isArabicAddressShow) {
                arabicAddressData = await that.getDetailedAddress(
                  that.lat,
                  that.long,
                  languageTypes.DEFAULT
                );
              }

              if (detailedAddress) {
                that.getMapData.emit({
                  lat: that.lat,
                  lng: that.long,
                  address: detailedAddress.address,
                  street_name: detailedAddress?.streetName,
                  city_name: detailedAddress?.city,
                  country: detailedAddress?.countryName,
                  postal_code: detailedAddress?.postalCode,
                  district: detailedAddress?.districtName,
                  province_name: detailedAddress?.districtName,
                  city_name_ar: arabicAddressData
                    ? arabicAddressData?.city
                    : "",
                });
              }
            });
          }
        }
      } else {
        input.value = "";
        that.toastr.error(mapErrorIssue.locationIssue);
      }
    });
  }

  /**
   * create marker
   */
  async createMarker(latLong: google.maps.LatLng | null = null): Promise<void> {
    this.setMapOnAll(); // Remove all existing markers
    this.markers = []; // Clear the marker array
    const marker = new google.maps.Marker({
      position: {
        lat: latLong?.lat() as number,
        lng: latLong?.lng() as number,
      },
      map: this.map,
      draggable: !this.isHideSearch,
    });
    this.markers.push(marker);
    this.map?.setZoom(this.map?.getZoom());

    google.maps.event.addListener(marker, "dragend", async () => {
      const position2 = marker.getPosition();
      if (position2) {
        const lat = position2.lat();
        const lng = position2.lng();
        const getdAddress: any = await this.getDetailedAddress(lat, lng);
        let arabicAddress: any = null;
        if (this.isArabicAddressShow) {
          arabicAddress = await this.getDetailedAddress(
            this.lat,
            this.long,
            languageTypes.DEFAULT
          );
        }
        this.getMapData.emit({
          lat: marker.getPosition()?.lat() as number,
          lng: marker.getPosition()?.lng() as number,
          address: getdAddress.address,
          street_name: getdAddress?.streetName,
          city_name: getdAddress?.city,
          country: getdAddress?.countryName,
          postal_code: getdAddress?.postalCode,
          district: getdAddress?.districtName,
          province_name: getdAddress?.districtName,
          city_name_ar: arabicAddress ? arabicAddress?.city : "",
        });
        this.isMapSelected = true;
      }
    });
  }

  /**
   * set map marker removed
   */
  setMapOnAll() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers = [];
  }

  /**
   * on gecoding latlng event
   * @param latlng google.maps.LatLng object
   */
  onLatLngEvent(latlng: google.maps.LatLng) {
    if (latlng && this.map) {
      this.map?.setCenter(latlng);
      // Set Timeout for zoom controlling
      setTimeout(() => {
        this.map.setZoom(this.map?.getZoom());
      }, 1500);
    }
  }

  /**
   * Get Address Details Save
   */
  private async getDetailedAddress(
    lat: number,
    lng: number,
    lan = languageTypes.DEFAULT_ADMIN
  ) {
    this.spinner.showLoader();
    try {
      const getDetails = await this.mapService
        .getFormattedAddress({
          latlng: `${lat},${lng}`,
          language: lan,
        })
        .pipe(first())
        .toPromise();

      if (getDetails.status_code !== this.statusCode.OK) {
        this.spinner.hideLoader();
        return "";
      }

      const result = getDetails?.data.results[0];

      if (!result?.formatted_address) {
        this.spinner.hideLoader();
        return "";
      }
      this.spinner.hideLoader();
      return this.extractAddressDetails(result);
    } catch (error) {
      return "";
    }
  }

  /**
   * Check if a place is within the bounds of South Arabia
   */
  isPlaceInSouthArabia(place: any) {
    if (!place?.geometry?.location) {
      // Handle the case where the place or its geometry is undefined
      return false;
    }

    // const southArabiaBounds = new google.maps.LatLngBounds(
    //   new google.maps.LatLng(10.0, 35.0), // Southwest corner of South Arabia
    //   new google.maps.LatLng(30.0, 60.0) // Northeast corner of South Arabia
    // );

    // // Extract latitude and longitude from place.geometry.location
    // const placeLatLng = new google.maps.LatLng(
    //   place.geometry.location.lat(),
    //   place.geometry.location.lng()
    // );

    // return southArabiaBounds.contains(placeLatLng);
    return true;
  }

  /**
   * Clear all markers from the map
   */
  clearMarkers() {
    this.setMapOnAll();
  }

  /**
   * Handle modal popup open
   * @params template string
   */
  openModal(template?: TemplateRef<string>) {
    const input = document.getElementById(
      "autocomplete_search_" + this.googleMapId
    ) as HTMLInputElement;
    input.value = "";
    this.modalRef = this.modalService.show(template, { class: "modal-xxl" });
  }

  /**
   * Handle modal popup close
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Handle submit map details
   */
  submitMapDetails(): void {
    this.getMapData.emit({
      lat: this.mapDetails?.lat as any,
      lng: this.mapDetails?.lng as any,
      address: this.mapDetails?.address,
      city_name: this.mapDetails?.city_name,
      street_name: this.mapDetails?.street_name ?? "",
      country: this.mapDetails?.country ?? "",
      postal_code: this.mapDetails?.postal_code ?? "",
      district: this.mapDetails?.district ?? "",
      province_name: this.mapDetails?.province_name ?? "",
      city_name_ar: this.mapDetails?.city_name_ar ?? "",
    });
    this.initMap();
    this.decline();
  }

  /**
   * Handle map location
   */
  getMapLocation(mapData: IMapProps) {
    this.mapDetails = mapData;
  }

  /**
   * Handle map on blur
   */
  onBlur() {
    // Collapse the input box only when the mouse is not hovering
    if (!this.isHovering) {
      this.isTyping = false;
    }
  }

  /**
   * Handle extract address details
   * @param result Map Data
   * @returns array
   */
  private extractAddressDetails(result: any) {
    const address = result.formatted_address || "";
    const components = result.address_components || [];

    const city = this.getComponentLongName(components, "locality");
    const countryName = this.getComponentLongName(components, "country");
    const postalCode = this.getComponentLongName(components, "postal_code");
    const streetName = this.getComponentLongName(components, "route");
    const districtName = this.getComponentLongName(
      components,
      "administrative_area_level_1"
    );

    return {
      address,
      city,
      countryName,
      postalCode,
      streetName,
      districtName,
    };
  }

  /**
   * Handle getcomponent long name
   * @param components
   * @param type
   * @returns
   */
  private getComponentLongName(components: any[], type: string): string {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : "";
  }
}
