import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { IErrorResponse } from "@model/errorResponse";
import { ISuccessResponse } from "@model/successResponse";
import {
  ICharge,
  IChargesDrpData,
  ITripAdditionalChargesPayload,
} from "@model/tripAdditionalCharges";
import { AdditionalChargesService } from "@services/additional-charges";
import { FileUploadService } from "@services/file-upload.service";
import { chargesType, fileUploadFormat, UserRole } from "@utils/enum-const";
import { cloneDeep } from "lodash";
import { first } from "rxjs/operators";

@Component({
  selector: "app-additional-charges-modal",
  templateUrl: "./additional-charges-modal.component.html",
  styleUrls: ["./additional-charges-modal.component.scss"],
})
@AutoUnsubscribe()
export class AdditionalChargesModalComponent extends BaseComponent
  implements OnInit {
  model: ITripAdditionalChargesPayload = {
    trip_id: null,
    order_id: null,
    additional_charges_id: null,
    sp_price: null,
    description: null,
    document: [],
    charges_type: chargesType.OCD,
  };
  additionalDocuments: any = [];
  @Input() tripData!: any;
  @Input() chargesType!: chargesType;
  submitted = false;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  @Output() AdditionalChargesSave = new EventEmitter<string>();
  @ViewChild("fileInput") fileInput!: ElementRef;

  additionalChargesDocument = [];
  fileUserType = UserRole;
  additionalChargesTypes!: ICharge[];
  constructor(
    private readonly additionalChargesService: AdditionalChargesService,
    private FileUpload: FileUploadService
  ) {
    super();
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click(); // Programmatically trigger the file input
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.close.emit();
  }

  /**
   * Init function
   */
  ngOnInit() {
    this.model = this.getDefaultPriceValues();
    this.getAdditionalChargesListing();
  }

  /**
   * Get title
   */
  getTranslatedText(): string {
    return this.chargesType === "OCD"
      ? "ADD_ADDITONAL_CHARGES"
      : "ADD_DOCUMENTS";
  }

  /**
   * Handle submit additional charge
   */
  async additionalChargesSubmit(form: NgForm) {
    this.submitted = true;
    this.spinner.showLoader();
    if (form.invalid) {
      this.spinner.hideLoader();
      return;
    }
    if (
      this.chargesType === chargesType.POD &&
      this.additionalDocuments?.length === 0
    ) {
      this.spinner.hideLoader();
      return;
    }
    const saveAdditionalCharges: ITripAdditionalChargesPayload = cloneDeep(
      this.model
    );
    if (this.chargesType === chargesType.POD) {
      delete saveAdditionalCharges["sp_price"];
      delete saveAdditionalCharges["sr_price"];
      delete saveAdditionalCharges["description"];
    }
    if (this.additionalDocuments?.length > 0) {
      await Promise.all(
        Array.from(this.additionalDocuments).map(
          async (event: any, index: number) => {
            const fileName = await this.onFileChange(event);
            if (fileName) {
              saveAdditionalCharges.document[index] = fileName;
            } else {
              saveAdditionalCharges.document.splice(index, 1);
            }
          }
        )
      );
    }
    this.saveAdditionalCharges(saveAdditionalCharges);
  }

  /**
   * Handle additional charges listing
   */
  getAdditionalChargesListing() {
    this.spinner.showLoader();
    this.additionalChargesService
      .getAdminAdditionalChargesTypes()
      .pipe(first())
      .subscribe(
        (response: IChargesDrpData) => {
          if (response.status_code === this.statusCode.OK) {
            this.additionalChargesTypes = response?.data?.data;
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle file select
   * @event event Event
   */
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      // Ensure `this.additionalDocuments` and `this.model.document` are initialized as arrays
      if (!Array.isArray(this.additionalDocuments)) {
        this.additionalDocuments = [];
      }
      if (!Array.isArray(this.model.document)) {
        this.model.document = [];
      }

      Array.from(input.files).forEach((file) => {
        if (file.size > 2 * 1024 * 1024) {
          this.toastr.error(`File: ${file.name} exceeds size limit of 2 MB`);
        } else {
          // Add file name to `this.model.document`
          this.model.document.push(file.name);

          // Add the actual file (or the file event) to `this.additionalDocuments`
          this.additionalDocuments.push(file);
        }
      });
    }
  }

  /**
   * Handle delete file upload
   * @event index number
   */
  deleteFileUpload(index: number): void {
    if (
      Array.isArray(this.model.document) &&
      index > -1 &&
      index < this.model.document.length
    ) {
      // Delete the file name from `this.model.document`
      this.model.document.splice(index, 1);

      // Delete the corresponding file from `this.additionalDocuments`
      this.additionalDocuments.splice(index, 1);
    } else {
      console.error("Invalid index or additionalDocuments is not properly set");
    }
  }

  /**
   * File reupload or change
   * @event event
   * @fieldName string
   * @returns file open
   */
  async onFileChange(event: Event): Promise<string | undefined> {
    try {
      const uploadedFileResult = await this.FileUpload.uploadAdditionalCharges(
        event,
        fileUploadFormat.DOCUMENT
      );
      if (uploadedFileResult) {
        const fileName = (uploadedFileResult as { fileName: string }).fileName;
        return fileName;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      return "";
    }
  }

  /**
   * Handle save additional charges
   * @param additionalChargesPayload
   * @author prashank.shah@brainvire.com
   */
  saveAdditionalCharges(additionalChargesPayload: any) {
    this.spinner.showLoader();
    this.additionalChargesService
      .saveAdditionalCharges(additionalChargesPayload)
      .pipe(first())
      .subscribe(
        (response: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (response.status_code === this.statusCode.OK) {
            this.toastr.success(response.message);
            this.AdditionalChargesSave.emit();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle set default value for prices
   * @author prashank.shah@brainvire.com
   */
  getDefaultPriceValues() {
    const modelValues: ITripAdditionalChargesPayload = {
      trip_id: this.tripData?.id,
      order_id: this.tripData?.order_id,
      additional_charges_id: null,
      sp_price: null,
      description: null,
      document: [],
      charges_type: this.chargesType,
    };
    if (this.isAdminRoute) {
      modelValues.sr_price = null; // Add sr_price to the object for admin routes
    }
    return modelValues;
  }
}
