<div *ngIf="mode === modeVal" class="thanksmsg-wrapper">
  <div class="thankyou-msg">
    <div class="icon-area">
      <i
        [ngClass]="
          paymentResponse.Result !== statusOfPayment.Successful
            ? 'icon-close'
            : 'icon-mark'
        "
      ></i>
    </div>
    <p
      *ngIf="paymentResponse.Result === statusOfPayment.Successful"
      class="header-data"
    >
      {{
        curLan === "en"
          ? "Your payment was successfully processed"
          : "تمت معالجة دفعتك بنجاح"
      }}
    </p>
    <p
      *ngIf="paymentResponse.Result !== statusOfPayment.Successful"
      class="header-data"
    >
      {{
        curLan === "en"
          ? "Payment was unsuccessful. Please try again."
          : "لم ينجح الدفع. حاول مرة اخرى."
      }}
    </p>
  </div>
</div>
