<div class="modal-header bg-primary">
  <h3 class="modal-title">
    {{ "CONFIRMATION" | translate }}
  </h3>
  <button type="button" class="close" (click)="decline()">
    <span aria-hidden="true">
      <i class="icon-close"></i>
    </span>
  </button>
</div>
<div class="modal-body text-center">
  <p>{{ popupMessage | translate }}</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn popup-btn-small bordered-btn"
    (click)="decline()"
  >
    {{ "NO" | translate }}
  </button>
  <button
    type="button"
    class="btn popup-btn-small btn-warning"
    (click)="handleLogout()"
  >
    {{ "YES" | translate }}
  </button>
</div>
