import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-spinner",
  template: `<ngx-spinner
    [type]="config.type"
    [color]="config.pbColor"
    [size]="config.size"
    [class]="config.class"
  ></ngx-spinner>`,
})
export class SpinnerComponent extends BaseComponent implements OnInit {
  // Declare the `config` field before any instance methods
  config = {
    type: "ball-spin-fade",
    pbColor: "#2da5de",
    size: "default",
    class: "spinner",
  };

  /**
   * Init function
   */
  ngOnInit() {
    if (!this.checkUserLoggedIn()) {
      this.config = {
        type: "ball-spin-fade",
        pbColor: "#2da5de",
        size: "default",
        class: "spinner login-spinner",
      };
    }
  }
}
