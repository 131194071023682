import { RegistrationTabs, VALIDATION_ERRORS } from "@config/app-constants";
import { TranslateService } from "@ngx-translate/core";
import { StatusCode } from "@utils/enum-const";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

export const isEmpty = (value: string) => {
  if (_.isEmpty(value)) {
    if (_.isNumber(value) || _.isBoolean(value)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const errorHandler = async (
  toastr: ToastrService | undefined,
  translate: TranslateService,
  error: any,
  callBack?: CallableFunction
) => {
  const errorData = error;
  if (!errorData) {
    const message = await translate.get("SOMETHING_WENT_WRONG").toPromise();
    toastr?.error(message);
    if (callBack) {
      callBack();
    }
    return;
  }
  if (
    errorData &&
    (errorData.message_code === "ERROR" ||
      errorData.message_code === VALIDATION_ERRORS)
  ) {
    for (const key in errorData.errors) {
      if (key) {
        toastr?.error(errorData.errors[key]);
      }
    }
  } else if (
    errorData.status_code === StatusCode.InternalServerError ||
    errorData?.message
  ) {
    toastr?.error(errorData.message);
  } else if (errorData?.errors?.message) {
    toastr?.error(errorData?.errors?.message);
  } else {
    const message = await translate.get("SOMETHING_WENT_WRONG").toPromise();
    toastr?.error(message);
  }

  if (callBack) {
    callBack();
  }
};

export const successHandler = (
  toastr: ToastrService,
  data: any,
  callBack?: CallableFunction,
  showToastr = true
) => {
  const msg = _.get(data, "meta.message", "");
  if (showToastr === true && !isEmpty(msg)) {
    toastr.success(msg);
  }
  if (callBack) {
    callBack(data);
  }
};

const getFileNameFromURL = (url: string) => {
  if (isEmpty(url)) return "";
  return url.replace(/^.*[\\/]/, "");
};

export const mapProperties: google.maps.MapOptions = {
  center: { lat: 24.7136, lng: 46.6753 },
  panControl: true,
  zoom: 10,
  minZoom: 2,
  zoomControl: true,
  streetViewControl: false,
  fullscreenControl: false,
  gestureHandling: "greedy",
  disableDefaultUI: true, // This will disable most default UI controls (including keyboard shortcuts)
};

export const registrationTabUser = (user: any) => {
  const isAlreadyRegistered = user.data.user;
  let activeTab = RegistrationTabs.ACCOUNT_DETAIL;
  if (isAlreadyRegistered.is_completed === 1) {
    activeTab = RegistrationTabs.ADDRESS_DETAIL;
  }
  if (isAlreadyRegistered.is_completed === 2) {
    activeTab = RegistrationTabs.UPLOAD_DOCUMENT;
  }
  if (isAlreadyRegistered.is_completed === 3) {
    activeTab = RegistrationTabs.CREATE_PASSWORD;
  }
  return activeTab;
};

export const downloadFile = async (data: any, callBack?: CallableFunction) => {
  const anchorElement = document.createElement("a");
  anchorElement.href = data.data.link;
  anchorElement.target = "_blank";
  anchorElement.download = getFileNameFromURL(data.data.link);
  document.body.appendChild(anchorElement); // we need to append the element to the dom -> otherwise it will not work in firefox
  anchorElement.click();
  anchorElement.remove();
  if (callBack) callBack();
};
