<div class="modal-header bg-primary">
  <h2 class="modal-title d-flex align-center">
    {{ getTranslatedText() | translate }}
  </h2>
</div>
<div class="modal-body text-center">
  <form
    #additionalChargesForm="ngForm"
    (ngSubmit)="additionalChargesSubmit(additionalChargesForm)"
    novalidate
  >
    <div class="enable-scroll">
      <div class="select-input">
        <div
          class="form-group"
          [ngClass]="model.additional_charges_id ? 'floatingLabel' : ''"
        >
          <div class="select-dropdown">
            <ng-select
              [items]="additionalChargesTypes"
              [id]="additional_charges_id"
              class="single"
              [name]="additional_charges_id"
              bindLabel="charges_en"
              bindValue="id"
              #additional_charges_id="ngModel"
              [notFoundText]="noDataFound"
              [searchable]="model.additional_charges_id ? false : true"
              required
              placeholder="{{ 'SELECT_ADDITIONAL_CHARGES' | translate }}"
              [(ngModel)]="model.additional_charges_id"
            >
            </ng-select>
            <div
              class="form-control-feedback text-danger"
              *ngIf="additional_charges_id.errors && submitted"
            >
              <span *ngIf="additional_charges_id.errors.required">
                {{ "PLEASE_SELECT_ADDITIONAL_CHARGES" | translate }}
              </span>
            </div>
          </div>
          <label class="floating-label">{{
            "SELECT_ADDITIONAL_CHARGES" | translate
          }}</label>
        </div>
      </div>
      <div class="input-section">
        <div
          class="file-upload"
          id="upload_profile"
          (click)="triggerFileInput()"
        >
          <i class="icon-upload" title="upload"></i>
          <span>{{ "UPLOAD_DOCUMENTS" | translate }}</span>
          <input
            #fileInput
            [dir]="isEnglish ? 'ltr' : 'rtl'"
            class="input-file"
            id="document"
            (change)="onFileSelected($event)"
            type="file"
            multiple
            [accept]="documentUploadConfig.ACCEPT"
          />
        </div>
      </div>
      <div *ngIf="this.model.document?.length > 0" class="uploaded-files">
        <div
          *ngFor="let file of this.model.document; let i = index"
          class="file-item"
        >
          <div class="form-group">
            <div class="file-upload" id="upload_profile">
              <i
                [class]="
                  getFileExtension(file) === this.fileExtensionType.PDF
                    ? fileIconType.pdf
                    : fileIconType.image
                "
              ></i>
              <input
                [title]="file"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                class="input-file"
                type="text"
              />
              <label tabindex="0" for="logo" class="input-file-trigger">
                <span class="file-return document-text" *ngIf="file != ''">{{
                  file
                }}</span></label
              >
            </div>
          </div>
          <a role="button" (click)="deleteFileUpload(i)" title="Delete">
            <i class="icon-delete"></i>
          </a>
        </div>
      </div>
      <div
        class="form-control-feedback text-danger"
        *ngIf="
          chargesType === 'POD' &&
          additionalDocuments?.length === 0 &&
          submitted
        "
      >
        <span>
          {{ "PLEASE_UPLOAD_DOCUMENTS" | translate }}
        </span>
      </div>

      <hr *ngIf="chargesType === 'OCD'" />

      <ng-container *ngIf="chargesType === 'OCD'">
        <div
          [ngClass]="{
            'price-inputs': isAdminRoute
          }"
        >
          <div
            class="form-group"
            [ngClass]="model.sp_price ? 'floatingLabel' : ''"
          >
            <span>
              <input
                appSpaceRemove
                type="text"
                id="sp_price"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                name="sp_price"
                [(ngModel)]="model.sp_price"
                class="form-control"
                #sp_price="ngModel"
                maxlength="6"
                required
                placeholder=" "
                appFloatingLabelInput
              />
              <div
                class="form-control-feedback text-danger"
                *ngIf="sp_price.errors && submitted"
              >
                <span
                  *ngIf="sp_price.errors.required || sp_price.errors.pattern"
                >
                  {{ "PLEASE_ENTER_PRICE" | translate }}
                </span>
              </div>
              <label class="floating-label lbl-sp_price" for="sp_price">{{
                "PRICE_OF_SP" | translate
              }}</label>
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="isAdminRoute"
            [ngClass]="model.sr_price ? 'floatingLabel' : ''"
          >
            <span>
              <input
                appSpaceRemove
                type="text"
                id="sr_price"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                name="sr_price"
                [(ngModel)]="model.sr_price"
                class="form-control"
                #sr_price="ngModel"
                maxlength="6"
                required
                placeholder=" "
                appFloatingLabelInput
              />
              <div
                class="form-control-feedback text-danger"
                *ngIf="sr_price.errors && submitted"
              >
                <span
                  *ngIf="sr_price.errors.required || sr_price.errors.pattern"
                >
                  {{ "PLEASE_ENTER_PRICE" | translate }}
                </span>
              </div>
              <label class="floating-label sr_price" for="sr_price">{{
                "PRICE_OF_SR" | translate
              }}</label>
            </span>
          </div>
        </div>
        <div class="d-col">
          <div
            class="form-group"
            [ngClass]="model.description ? 'floatingLabel' : ''"
          >
            <span>
              <textarea
                [(ngModel)]="model.description"
                name="description"
                class="form-control"
                cols="3"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                rows="4"
                id="description"
                #description="ngModel"
                placeholder=" "
                appFloatingLabelInput
                maxlength="300"
              >
              </textarea>
              <label class="floating-label" for="comment">{{
                "COMMENT" | translate
              }}</label>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-small bordered-btn"
        (click)="decline()"
      >
        {{ "CANCEL" | translate }}
      </button>
      <button type="submit" class="btn btn-small btn-warning">
        {{ "SAVE" | translate }}
      </button>
    </div>
  </form>
</div>
