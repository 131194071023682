import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import {
  ITermAndPolicyData,
  ITermsAndConditionAndPrivacyPolicy,
} from "@model/contact";
import { IErrorResponse } from "@model/errorResponse";
import { BaseComponent } from "@components/base.component";
import { languageTypes, sectionType } from "@utils/enum-const";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { LanguageService } from "@services/language.service";
import { CommonApiService } from "@services/common-api.service";

@Component({
  selector: "app-privacy-policy-section",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
@AutoUnsubscribe()
export class PrivacyPolicyComponent extends BaseComponent implements OnInit {
  @Input() section!: string;
  @Output() closeModal = new EventEmitter();
  sectionType = sectionType;
  privacyPolicyData!: ITermAndPolicyData;
  isEnglish = false;
  routeSub!: Subscription;
  _id!: string;
  constructor(
    private commonService: CommonApiService,
    private translate: TranslateService,
    private languageSwitcher: LanguageService,
    private route: ActivatedRoute
  ) {
    super();
  }

  /**
   * Handle init function
   */
  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this._id = params["lang"];
      setTimeout(() => {}, 100);
    });
    if (this._id) {
      this.translate.use(this._id);
      this.isEnglish = this._id === languageTypes.DEFAULT_ADMIN;
    } else {
      const userKey = this.getUser() + "_lan";
      const currentLanguage = this.languageSwitcher.getUserDefaultLanguage(
        userKey
      );
      this.isEnglish = currentLanguage === languageTypes.DEFAULT_ADMIN;
    }
    this.spinner.showLoader();
    this.commonService
      .getPrivacyPolicy()
      .pipe(first())
      .subscribe(
        (response: ITermsAndConditionAndPrivacyPolicy) => {
          if (response) {
            this.spinner.hideLoader();
            this.privacyPolicyData = response.data;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle change language
   */
  changeLang() {
    if (!this.isEnglish) {
      this.isEnglish = true;
    } else {
      this.isEnglish = false;
    }
  }

  /**
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.spinner.showLoader();

    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  /**
   * Handle delete click
   */
  handleDelete() {
    this.decline();
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.closeModal.emit(true);
  }
}
