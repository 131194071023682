import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONFIG } from "@config/app-config";
import {
  FilterPayloadOptions,
  InvoiceNotesResponse,
  OrderList,
} from "@model/adminContract";
import {
  IBayanTrips,
  IShipmentPayload,
  IVehicleAssignment,
} from "@model/common";
import { ICancelTripRequest } from "@model/contract";
import {
  IOrderDetailData,
  IRejectOrder,
  IVehicleDriverData,
} from "@model/directOrdersDetails";
import { IAddDriverListing } from "@model/driver";
import {
  ProviderListApiResponse,
  RequesterListApiResponse,
} from "@model/providerList";
import {
  IAssignProviderPayload,
  InvoiceNotes,
  IRescheduleOrderPayload,
  ItripProviderPayload,
  IVehicleListApiPayload,
} from "@model/serviceModel";
import {
  IAddEditResponseSuccessReponse,
  ISuccessResponse,
} from "@model/successResponse";
import { IGetTruckInterface } from "@model/truck";
import { UserRole } from "@utils/enum-const";
import { map } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class AdminContractsService {
  userType = "";
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Get admin contract list data
   * @returns Object
   */
  getAdminContractListData(data: FilterPayloadOptions) {
    return this.http.post<OrderList>(CONFIG.getAdminContractsList, data).pipe(
      map((response: OrderList) => {
        return response;
      })
    );
  }

  /**
   * Edit Trip Date and Time
   * @returns Object
   */
  editTripDateAndTime(
    tripId: number | undefined,
    data: {
      loading_date?: string;
      eta_date?: string;
      offloading_date?: string;
    }
  ) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const standardizedTimeZone =
      timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone;

    const dataWithTimezone = {
      ...data,
      timezone: standardizedTimeZone,
    };
    let getEditDateTimeURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getEditDateTimeURL = CONFIG.editTripDateTime;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getEditDateTimeURL = CONFIG.providerEditTripDateTime;
    } else {
      getEditDateTimeURL = CONFIG.editTripDateTime;
    }
    return this.http
      .post<ISuccessResponse>(getEditDateTimeURL + tripId, dataWithTimezone)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Get order details by id
   * @orderId Number
   * @returns Object
   */
  getOrderDetailsById(orderId: number) {
    return this.http
      .get<IOrderDetailData>(CONFIG.orderDetailsById + orderId)
      .pipe(
        map((response: IOrderDetailData) => {
          return response;
        })
      );
  }

  /**
   * Assign providers API call
   * @assignPayload IAssignProviderPayload - Provider assign
   * @returns Object
   */
  assignProviders(assignPayload: IAssignProviderPayload) {
    return this.http
      .post<ISuccessResponse>(CONFIG.postAssignProviders, assignPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle accept or reject order
   * @orderId Number
   * @selectedStatus string
   * @returns Object
   */
  acceptRejectContractOrder(
    orderId: string,
    selectedStatus: {
      status: string;
      status_notes?: string;
    }
  ) {
    return this.http
      .post<ISuccessResponse>(
        CONFIG.acceptRejectContractOrder + orderId,
        selectedStatus
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle get vehicle listing for contract
   * @vehicleListPayload IVehicleListApiPayload
   */
  getVehicleForContract(vehicleListPayload: IVehicleListApiPayload) {
    return this.http
      .post<IGetTruckInterface>(
        CONFIG.getVehicleListContractOrder,
        vehicleListPayload
      )
      .pipe(
        map((response: IGetTruckInterface) => {
          return response;
        })
      );
  }

  /**
   * Handle get driver listing for contract
   * @orderId Number - Order ID
   */
  getDriverForContract(orderId: { provider_id?: number }) {
    return this.http
      .post<IAddDriverListing>(CONFIG.getDriverListContractOrder, orderId)
      .pipe(
        map((response: IAddDriverListing) => {
          return response;
        })
      );
  }

  /**
   * Handle get trip listing based on order ID
   * @orderId Number - Order ID
   */
  getTripList(orderId: number) {
    return this.http
      .get<IAddDriverListing>(CONFIG.getTripListDetails + orderId)
      .pipe(
        map((response: IAddDriverListing) => {
          return response;
        })
      );
  }

  /**
   * Handle get trip details based on order ID
   * @orderId Number - Order ID
   */
  getTripDetails(orderId: number) {
    return this.http
      .get<{ data: IBayanTrips }>(CONFIG.getTripDetailsById + orderId)
      .pipe(
        map((response: { data: IBayanTrips }) => {
          return response;
        })
      );
  }

  /**
   * Get invoice list for speicific order
   * @orderId Number - Order ID
   */
  getInvoiceList(order_id: number) {
    return this.http
      .get<InvoiceNotesResponse>(CONFIG.adminContractInVoiceData + order_id)
      .pipe(
        map((response: InvoiceNotesResponse) => {
          return response;
        })
      );
  }

  /**
   * Add invoice data
   * @orderId Number - Order ID
   * @invoicePayload - Invoice save payload data
   */
  addInvoiceData(id: number, invoicePayload: InvoiceNotes) {
    return this.http
      .post<ISuccessResponse>(
        CONFIG.adminContractInVoiceData + id,
        invoicePayload
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle cancel order
   * @orderId Number - Order ID
   * @payload - Reason for cancel order
   */
  cancelContractOrder(id_order: number, payload: IRejectOrder) {
    return this.http
      .post<ISuccessResponse>(CONFIG.cancelContractOrder + id_order, payload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle reschedule order
   * @orderId Number - Order ID
   * @datePayload - Date that need to save for reschedule
   */
  rescheduleOrder(ord_id: number, datePayload: IRescheduleOrderPayload) {
    return this.http
      .post<ISuccessResponse>(
        CONFIG.rescheduleContractOrder + ord_id,
        datePayload
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Update driver truck data
   * @tripId Number - Trip Id
   * @detailsPayload - Data for update driver truck
   */
  updateDriverTruck(tripId: number | null, detailsPayload: IVehicleAssignment) {
    return this.http
      .post<ISuccessResponse>(
        CONFIG.updateDriverTruckAdmin +
          tripId +
          CONFIG.updateDriverTruckPrefixUrl,
        detailsPayload
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle create contract order
   * @contractPayload Object - Create contract order payload
   */
  createContractOrder(contractPayload: IShipmentPayload) {
    let createNewContractURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      createNewContractURL = CONFIG.createNewAdminContractOrder;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      createNewContractURL = CONFIG.createNewRequesterContractOrder;
    } else {
      createNewContractURL = CONFIG.createNewProviderContractOrder;
    }
    return this.http
      .post<ISuccessResponse>(createNewContractURL, contractPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Get requester listing data
   */
  getRequesterList() {
    return this.http
      .get<RequesterListApiResponse>(CONFIG.requesterListForNewContract)
      .pipe(
        map((response: RequesterListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle add driver or truck data for specific order
   * @addPayload Object - Create contract order payload
   * @id Number
   */
  addDriverTruckAdminContract(addPayload: IVehicleDriverData, id: number) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const standardizedTimeZone =
      timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone;
    const payloadWithTimezone = {
      ...addPayload,
      timezone: standardizedTimeZone,
    };
    return this.http
      .post<ISuccessResponse>(
        CONFIG.addDriverTruckAdmin + id + CONFIG.createDriverTruckPrefixUrl,
        payloadWithTimezone
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle change contract order trip status
   * @status string - Status that need to update
   * @id Number
   */
  changeContractOrderTripStatus(status: string, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.changeContractOrderTripStatusURL + id, {
        status: status,
      })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle cancel trip
   * @id Number - Trip ID
   * @requestPayload - Cancel trip reason
   */
  cancelTrip(
    id: number | null | undefined,
    requestPayload: ICancelTripRequest
  ) {
    return this.http
      .post<ISuccessResponse>(CONFIG.cancelTrip + id, requestPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle fetch provider listing for assign provider
   * @id Number - Trip ID
   */
  fetchProviderListToAssignProvider(id: string | undefined) {
    return this.http
      .post<ProviderListApiResponse>(CONFIG.fetchProviderListToAssign, {
        order_id: id,
      })
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle cancel trip
   * @assignPayload - Reassign provider payload
   */
  reAssignProviders(assignPayload: IAssignProviderPayload) {
    return this.http
      .post<ISuccessResponse>(CONFIG.reAssignProvider, assignPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle fetch provider listing for assign provider
   * @assignTripPayload Reassign provider payload
   * @tripId Number - Trip ID
   * @providerId Provider Id
   */
  reAssignTripProvider(
    assignTripPayload: ItripProviderPayload,
    tripId: number,
    providerId: number
  ) {
    return this.http
      .post<IAddEditResponseSuccessReponse>(
        CONFIG.reAssignTripProviderURL + tripId + "/" + providerId,
        assignTripPayload
      )
      .pipe(
        map((response: IAddEditResponseSuccessReponse) => {
          return response;
        })
      );
  }
}
