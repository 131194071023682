<div class="d-col">
  <div class="map-area google-map" [ngClass]="googleMapId">
    <div
      class="map"
      [ngClass]="googleMapId ? 'destination-graph' : ''"
      [attr.id]="googleMapId"
    ></div>
    <a
      class="map-modal"
      *ngIf="!isMapModalView"
      (click)="openModal(openMarkersMapModal)"
    >
      <i class="icon-map-zoom"></i>
    </a>
  </div>
</div>

<ng-template #openMarkersMapModal>
  <app-multiple-markers-map
    [googleMapId]="'modal-map'"
    [(latlong)]="this.latlong"
    [isMapModalView]="true"
  >
  </app-multiple-markers-map>
  <div id="save-map">
    <div class="d-col modal-footer just-end map-modal-open">
      <a
        role="button"
        (click)="closeMapModal()"
        class="btn bordered-btn popup-btn-small map-btn"
        >{{ "CANCEL" | translate }}</a
      >
    </div>
  </div>
</ng-template>
