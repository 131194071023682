<div class="cancel-modal d-flex flex-col" *ngIf="!this.isCancel">
  <span class="cross-btn"><i class="icon-close"></i></span>
  <h1>{{ "REJECT_ORDER" | translate }}</h1>
  <span class="cancel-text">{{
    "ARE_YOU_SURE_YOU_WANT_TO_REJECT_THE_ORDER" | translate
  }}</span>
  <div class="btn-area d-flex">
    <a (click)="this.closePopup()" class="btn bordered-btn popup-btn-small">{{
      "NO" | translate
    }}</a>
    <a (click)="this.cancel()" class="btn btn-secondary popup-btn-small">{{
      "YES" | translate
    }}</a>
  </div>
</div>
<div class="cancel-modal d-flex flex-col" *ngIf="this.isCancel">
  <h1 class="cancel-reason">{{ "REJECTION_REASON" | translate }}</h1>
  <form
    (ngSubmit)="cancelOrderSubmit(cancelOrder)"
    class="cancel-reason-form"
    novalidate
    #cancelOrder="ngForm"
    InvalidFormScrollNGFormDirective
  >
    <div class="form-group" [ngClass]="model.comment ? 'floatingLabel' : ''">
      <span>
        <textarea
          [(ngModel)]="model.comment"
          name="comment"
          class="form-control"
          cols="3"
          rows="4"
          id="comment"
          [dir]="isEnglish ? 'ltr' : 'rtl'"
          #comment="ngModel"
          placeholder=" "
          maxlength="300"
          minlength="3"
          required
          appFloatingLabelInput
        >
        </textarea>
        <div
          class="form-control-feedback text-danger"
          *ngIf="comment.errors && submitted"
        >
          <span *ngIf="comment.errors.required">
            {{ "PLEASE_ENTER_COMMENT" | translate }}
          </span>
          <span *ngIf="comment.errors.minlength || comment.errors.maxlength">
            {{ "INVALID_COMMENT_LENGTH" | translate }}
          </span>
        </div>
        <label class="floating-label" for="comment">{{
          "REASON" | translate
        }}</label>
      </span>
    </div>
    <button type="submit" class="btn btn-secondary btn-small cancelSubmit">
      {{ "SUBMIT" | translate }}
    </button>
  </form>
</div>
