<div class="order-info">
  <h3 class="m-0">
    {{ "ORDER_DETAILS" | translate }}
  </h3>
  <div
    class="cancel-order-box"
    *ngIf="
      this.orderDetails?.status === this.orderStatus?.CANCELLED &&
      this.orderDetails?.order_cancel_by
    "
  >
    <!-- <div class="cancel-order-title d-flex align-center">
      <span class="heading-left"
        >{{ "ORDER_CANCEL_BY" | translate }} &nbsp;</span
      >
      <span class="heading-right"
        >{{ this.orderDetails?.order_cancel_by?.user?.name }}&nbsp;({{
          getCancelOrderAuthorDetails(this.orderDetails)
        }}) &nbsp;{{ "AT" | translate }}&nbsp;{{
          this.orderDetails?.order_cancel_by?.cancelled_at
            | customDate: momentDateTimeFormat
        }}</span
      >
    </div>
    <p class="cancel-reason">{{ this.orderDetails?.refused_comment }}</p> -->
    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <div class="cancellation-reason-text">
            <strong class="reason-title"
              >{{ "CANCELLATION_REASON" | translate }}:</strong
            >
            <span class="cancelled-reason">{{
              this.orderDetails?.refused_comment
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <span class="reason-text">
            <strong class="reason-title"
              >{{ "ORDER_CANCEL_BY" | translate }}:</strong
            >
            {{ this.orderDetails?.order_cancel_by?.user?.name }}&nbsp;({{
              getCancelOrderAuthorDetails(this.orderDetails)
            }}) &nbsp;{{ "AT" | translate }}&nbsp;{{
              this.orderDetails?.order_cancel_by?.cancelled_at
                | customDate: momentDateTimeFormat
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="order-data space-between">
    <div class="status-btn-wrapper">
      <ng-container
        *ngIf="
          this.orderDetails?.status === this.orderStatus.WAITING_FOR_APPROVAL ||
          this.orderDetails?.status ===
            this.orderStatus?.WAITING_FOR_APPROVAL_SR ||
          this.orderDetails?.status === this.orderStatus?.SR_INVOICE_SENT ||
          this.orderDetails?.status === this.orderStatus?.INVOICE_PAID ||
          this.orderDetails?.status === this.orderStatus?.CANCELLED
        "
      >
        <div class="status-box">
          <div
            class="state"
            [ngClass]="{
              'status-approval-yellow':
                this.orderDetails?.status ===
                  this.orderStatus?.WAITING_FOR_APPROVAL ||
                this.orderDetails?.status ===
                  this.orderStatus?.WAITING_FOR_APPROVAL_SR,
              'status-new':
                this.orderDetails?.status !==
                  this.orderStatus?.WAITING_FOR_APPROVAL &&
                this.orderDetails?.status !==
                  this.orderStatus?.WAITING_FOR_APPROVAL_SR
            }"
          >
            <div class="icon-area">
              <i
                *ngIf="
                  this.orderDetails?.status !==
                    this.orderStatus?.WAITING_FOR_APPROVAL &&
                  this.orderDetails?.status !==
                    this.orderStatus?.WAITING_FOR_APPROVAL_SR
                "
                class="icon-mark"
              ></i>
            </div>
            <span
              [ngStyle]="{ color: orderDetails?.status_color?.hex }"
              class="orderStatusTruncate"
            >
              {{ this.orderDetails?.orderStatus }}
            </span>
          </div>
          <label class="floating-label">{{ "STATUS" | translate }}</label>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          this.orderDetails?.status ===
            this.orderStatus?.SHIPMENT_IN_PROGRESS ||
          this.orderDetails?.status === this.orderStatus?.SHIPMENT_ON_THE_WAY ||
          this.orderDetails?.status === this.orderStatus?.DELIVERED ||
          this.orderDetails?.status === this.orderStatus?.INVOICE_PAID_SP ||
          (this.orderDetails?.status === this.orderStatus?.INVOICE_SENT_SP &&
            this.orderDetails?.status !== this.orderStatus?.CANCELLED)
        "
      >
        <div class="dropdown">
          <div class="form-group floatingLabel">
            <div class="icon-area"><span class="fill"></span></div>
            <select
              class="form-control-select status-box"
              #order_status="ngModel"
              required
              id="order_status"
              [(ngModel)]="model.order_status"
              name="order_status"
              (change)="
                this.orderDetails?.status === this.orderStatus?.INVOICE_PAID_SP
                  ? openConfirmationModal(confirmationDirectOrderPopup)
                  : changeOrderStatus()
              "
              [ngStyle]="{ color: orderDetails?.status_color?.hex }"
            >
              <option
                [value]="model.order_status"
                class="select-dropdown__list-item"
                selected
              >
                {{ this.orderDetails?.orderStatus }}
              </option>
              <ng-container *ngFor="let item of orderStatusList">
                <ng-container *ngIf="item.key !== this.orderDetails?.status">
                  <option [value]="item.key" class="select-dropdown__list-item">
                    {{ item.label }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
            <i class="icon-arrow-down"></i>
            <label class="floating-label" for="order_status">{{
              "STATUS" | translate
            }}</label>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="shipment-information">
      <div class="shipment-box">
        <div
          [ngClass]="storeProgressBarClass"
          class="circular-progress"
          appCircularProgress
          [currentTrips]="this.orderDetails?.tripStatues?.at_loading_location"
          [totalTrips]="this.orderDetails?.quantity"
        >
          <span class="progress-value">
            {{ this.orderDetails?.tripStatues?.at_loading_location }}/{{
              this.orderDetails?.quantity
            }}
          </span>
        </div>
        <span class="shipment-status">
          {{ "SHIPMENT_IN_PROGRESS" | translate }}
        </span>
      </div>
      <div class="shipment-box">
        <div
          [ngClass]="storeProgressBarClass"
          class="circular-progress"
          appCircularProgress
          [currentTrips]="this.orderDetails?.tripStatues?.on_the_way_trip"
          [totalTrips]="this.orderDetails?.quantity"
        >
          <span class="progress-value">
            {{ this.orderDetails?.tripStatues?.on_the_way_trip }}/{{
              this.orderDetails?.quantity
            }}
          </span>
        </div>
        <span class="shipment-status">
          {{ "SHIPMENT_ON_THE_WAY" | translate }}
        </span>
      </div>
      <div class="shipment-box">
        <div
          [ngClass]="storeProgressBarClass"
          class="circular-progress"
          appCircularProgress
          [currentTrips]="this.orderDetails?.tripStatues?.at_unloading_location"
          [totalTrips]="this.orderDetails?.quantity"
        >
          <span class="progress-value">
            {{ this.orderDetails?.tripStatues?.at_unloading_location }}/{{
              this.orderDetails?.quantity
            }}
          </span>
        </div>
        <span class="shipment-status">
          {{ "DELIEVERD" | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="border-box">
    <div class="detail-area">
      <div class="row">
        <div class="d-col d-col-8">
          <div class="detail-data">
            <span class="title">{{ "ORDER_CREATED_ON" | translate }}</span>
            <span class="detail">{{
              this.orderDetails?.created_at | customDate: dateFormatListing
            }}</span>
          </div>
        </div>
        <div class="d-col d-col-8">
          <div class="detail-data">
            <span class="title">{{ "ORDER_TYPE" | translate }}</span>
            <span class="detail">{{
              this.orderDetails?.offer_id
                ? this.orderType.offer_order
                : this.orderType[this.orderDetails?.section]
            }}</span>
          </div>
        </div>
        <div
          class="d-col d-col-8"
          *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'USER',
            'price_show_direct_order'
          ]"
        >
          <div class="detail-data">
            <span class="title">{{ "PRICE(SR)" | translate }}</span>
            <span class="detail">{{
              this.orderDetails?.price +
                " X " +
                this.orderDetails?.quantity +
                " = " +
                this.orderDetails?.sub_total +
                " " +
                this.measurementUnits.SAR
            }}</span>
          </div>
        </div>
        <div
          class="d-col d-col-8"
          *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'USER',
            'price_show_direct_order'
          ]"
        >
          <div class="detail-data">
            <span class="title">{{ "PRICE(SP)" | translate }}</span>
            <span class="detail">{{
              row?.order_prices?.sp_price +
                " X " +
                row?.order_prices?.quantity +
                " = " +
                row?.order_prices?.sub_total +
                " " +
                this.measurementUnits.SAR
            }}</span>
          </div>
        </div>
        <div
          class="d-col d-col-8"
          *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'USER',
            'price_show_direct_order'
          ]"
        >
          <div class="detail-data">
            <span class="title"
              >{{ "TOTAL_PRICE_SR" | translate }} ({{ "INCL_VAT" | translate }}
              <b>{{ this.orderDetails?.vat_percentage }}</b
              >%)</span
            >
            <span class="detail">{{
              (this.orderDetails?.total_price).toFixed(0) +
                " " +
                this.measurementUnits.SAR
            }}</span>
          </div>
        </div>
        <div
          class="d-col d-col-8"
          *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'USER',
            'price_show_direct_order'
          ]"
        >
          <div class="detail-data">
            <span class="title"
              >{{ "TOTAL_PRICE_SP" | translate }} ({{ "INCL_VAT" | translate }}
              <b>{{ this.orderDetails?.vat_percentage }}</b
              >%)</span
            >
            <span class="detail">{{
              this.orderDetails?.providers.length > 0
                ? this.orderDetails?.sp_price
                : this.orderDetails?.order_prices?.sp_price_vat +
                  " " +
                  this.measurementUnits.SAR
            }}</span>
          </div>
        </div>
        <div
          class="d-col d-col-8"
          *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'USER',
            'price_show_direct_order'
          ]"
        >
          <div class="detail-data br-none">
            <span class="title">{{ "PROFIT" | translate }}</span>
            <span class="detail" [ngStyle]="">{{
              this.orderDetails?.order_prices?.shahen_profit +
                " " +
                this.measurementUnits.SAR
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngxPermissionsOnly="['SUPER_ADMIN', 'price_show_direct_order']">
      <div
        class="invoice-box"
        *ngIf="
          this.orderDetails?.status !==
            this.orderStatus?.WAITING_FOR_APPROVAL &&
          this.orderDetails?.status !== this.orderStatus?.CANCELLED
        "
      >
        <a
          (click)="downloadInvoice(userType.PROVIDER)"
          class="btn btn-medium btn-secondary btn-small"
        >
          <i class="icon-Invoice"></i>{{ "VIEW_INVOICE_SP" | translate }}</a
        >
        <a
          (click)="downloadInvoice(userType.REQUESTER)"
          class="btn btn-medium btn-secondary btn-small"
        >
          <i class="icon-Invoice"></i>{{ "VIEW_INVOICE_SR" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
<ng-template #confirmationDirectOrderPopup>
  <app-confirmation-popup-modal
    [popupMessage]="'CONFIRM_PAID_STATUS_MSG'"
    (submit)="approveChange()"
    (close)="declineChange()"
  >
  </app-confirmation-popup-modal>
</ng-template>
