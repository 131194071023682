<header class="d-flex space-between">
  <div class="header-left">
    <h1>{{ "TRIP_DETAILS" | translate }}</h1>
    <p class="header-data">
      {{ "ORDER_NUMBER" | translate }} - #{{ this.orderData?.order_id }}
    </p>
  </div>
  <div class="header-right">
    <span class="close" (click)="this.closePopup()"
      ><i class="icon-close"></i
    ></span>
  </div>
</header>
<div class="body-wrapper custom-scrollbar">
  <ng-container *ngFor="let orderTrip of listTripData">
    <div class="body-part">
      <div class="trip-left-box">
        <div class="trip-id">
          <span class="trip-data" (click)="redirectToTripDetail(orderTrip.id)"
            >{{ orderTrip.trip_id }}
          </span>
        </div>
        <div class="truck-info-table">
          <table>
            <thead>
              <tr>
                <th class="pl5">{{ "TRUCK_PRICE" | translate }}</th>
                <th>{{ orderTrip?.trip_price?.price }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" class="additional-charges-title pl5">
                  {{ "ADDITIONAL_CHARGES" | translate }} :
                </td>
              </tr>
              <ng-container *ngIf="orderTrip?.additional_charges.length > 0">
                <ng-container
                  *ngFor="
                    let additionalChargeData of orderTrip?.additional_charges
                      | slice: -2
                  "
                >
                  <tr>
                    <td class="pl5">
                      {{ additionalChargeData?.charges?.charges_en }} :
                    </td>
                    <td>
                      <span class="price">{{
                        additionalChargeData?.sp_price
                      }}</span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr
                *ngIf="!this.orderData?.shahen_order && isServiceProviderRoute"
              >
                <td class="pl5">
                  <div class="add-more">
                    <span
                      (click)="openProviderTripAdditionalChargeModal(orderTrip)"
                      (keydown)="
                        openProviderTripAdditionalChargeModal(orderTrip)
                      "
                      ><i class="icon-Plus"></i>
                      {{ "ADD_MORE" | translate }}</span
                    >
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-total">
          <div class="pl5">
            <span class="bold-text">{{ "TOTAL" | translate }}</span>
          </div>
          <div>
            <span class="bold-text">{{
              orderTrip?.trip_price?.trip_price_with_additional_charges
            }}</span>
          </div>
        </div>
      </div>
      <div class="trip-detail">
        <div class="trip-data-top">
          <div class="col-6">
            <div class="title-button">
              <div
                *ngIf="
                  !(orderTrip?.is_bayan_sync == 1 && orderTrip?.closed == 1) &&
                  !(
                    orderTrip?.is_canceled == 1 &&
                    orderTrip?.is_bayan_cancel == 1
                  ) &&
                  buttonLabel(orderTrip) === 'BAYAN_SYNC'
                "
                class="trip-data-msg"
              >
                <span class="sync-msg">
                  {{ "SYNC_BUTTON_MESSAGE" | translate }}
                </span>
              </div>
              <div class="buttons-part">
                <button
                  [ngClass]="
                    orderTrip?.is_bayan_sync === tripSyncFlag.zero
                      ? 'btn btn-small disabled-sync'
                      : 'btn btn-small btn-primary'
                  "
                  (click)="
                    orderTrip?.is_bayan_sync !== tripSyncFlag.zero
                      ? downloadBayanWaybillFile(orderTrip?.id, 'bayan')
                      : ''
                  "
                  [disabled]="orderTrip?.is_bayan_sync === tripSyncFlag.zero"
                >
                  {{ "BAYAN" | translate }}
                </button>

                <button
                  class="btn btn-small btn-primary"
                  (click)="downloadBayanWaybillFile(orderTrip?.id, 'waybill')"
                >
                  {{ "WAY_BILL" | translate }}
                </button>
                <button
                  *ngIf="this.isButtonVisible(orderTrip)"
                  [ngClass]="{
                    'bayan-close': buttonLabel(orderTrip) === 'BAYAN_CLOSE',
                    'btn-medium-reject':
                      buttonLabel(orderTrip) === 'BAYAN_CANCEL'
                  }"
                  class="btn btn-small btn-primary"
                  (click)="manageBayanSyncAction(orderTrip)"
                >
                  {{ buttonLabel(orderTrip) | translate }}
                </button>

                <button
                  class="btn btn-medium btn-primary"
                  (click)="redirectToChat(orderTrip?.trip_id)"
                >
                  {{ "CHAT" | translate }}
                </button>

                <button class="btn btn-small btn-primary">
                  {{ "ADD_DOC" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="trip-info-container">
            <div class="wrap-container">
              <div class="col-3 w-date-time">
                <span class="title">{{ "LOADING_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span>{{
                      orderTrip?.loading_date
                        | customDate: moment24DateOnlyFormat
                    }}</span>
                    <span
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.loading_date
                          | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>

                  <a
                    *ngIf="orderData?.shahen_order !== 1"
                    class="icon-edit-box"
                    (click)="
                      openModal(
                        editShipmentDateTimePopup,
                        orderTrip?.id,
                        'EDIT_LOADING_DATE_AND_TIME',
                        orderTrip?.loading_date,
                        orderTrip.next_status !== ''
                          ? orderTrip?.eta_date
                          : orderTrip?.offloading_date
                      )
                    "
                  >
                    <i class="icon-edit"></i>
                  </a>
                </div>
              </div>
              <div
                class="col-3 w-date-time"
                *ngIf="
                  orderTrip.next_status !== this.orderTripStatusList[2]?.key &&
                  orderTrip.next_status !== this.orderTripStatusList[1]?.key &&
                  orderTrip.next_status === ''
                "
              >
                <span class="title">{{ "DELIVERED_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span
                      *ngIf="
                        orderTrip.next_status !==
                          this.orderTripStatusList[2]?.key &&
                        orderTrip.next_status !==
                          this.orderTripStatusList[1]?.key &&
                        orderTrip?.offloading_date
                      "
                      >{{
                        orderTrip?.offloading_date
                          | customDate: moment24DateOnlyFormat
                      }}</span
                    >
                    <span
                      *ngIf="
                        orderTrip.next_status !==
                          this.orderTripStatusList[2]?.key &&
                        orderTrip.next_status !==
                          this.orderTripStatusList[1]?.key &&
                        orderTrip?.offloading_date
                      "
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.offloading_date
                          | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>

                  <a
                    *ngIf="orderData?.shahen_order !== 1"
                    (click)="
                      openModal(
                        editShipmentDateTimePopup,
                        orderTrip?.id,
                        'EDIT_DELIVERED_DATE_AND_TIME',
                        orderTrip?.offloading_date,
                        orderTrip?.loading_date
                      )
                    "
                    class="icon-edit-box"
                  >
                    <i class="icon-edit"></i>
                  </a>
                </div>
              </div>
              <div
                class="col-3 w-date-time"
                *ngIf="orderTrip.next_status !== '' && orderTrip?.eta_date"
              >
                <span class="title">{{ "ETA_DATE" | translate }}</span>
                <div class="trip-date-time">
                  <div class="date-time-wrap">
                    <span
                      *ngIf="
                        orderTrip.next_status !== '' && orderTrip?.eta_date
                      "
                      >{{
                        orderTrip?.eta_date | customDate: moment24DateOnlyFormat
                      }}</span
                    >
                    <span
                      *ngIf="
                        orderTrip.next_status !== '' && orderTrip?.eta_date
                      "
                      >{{ "TIME" | translate }}:
                      {{
                        orderTrip?.eta_date | customDate: moment24TimeOnlyFormat
                      }}</span
                    >
                  </div>

                  <a
                    *ngIf="
                      orderTrip.next_status !== '' &&
                      orderData?.shahen_order !== 1
                    "
                    class="icon-edit-box"
                    (click)="
                      orderTrip?.loading_date &&
                        openModal(
                          editShipmentDateTimePopup,
                          orderTrip?.id,
                          'EDIT_ETA_DATE_AND_TIME',
                          orderTrip?.eta_date,
                          orderTrip?.loading_date
                        )
                    "
                  >
                    <i
                      [ngClass]="{
                        'icon-edit': true,
                        disabled: !orderTrip?.loading_date
                      }"
                    ></i>
                  </a>
                </div>
              </div>
              <div class="col-3 driver-name">
                <span class="title">{{ "DRIVER_NAME" | translate }}</span>
                <span class="desc" title="{{ orderTrip.driver?.name }}">{{
                  orderTrip.driver?.name
                }}</span>
              </div>
              <div class="col-3">
                <span class="title">{{ "NATIONAL_ID" | translate }}</span>
                <span class="desc">{{ orderTrip.driver?.national_id }}</span>
              </div>
              <div class="col-3 w-truck">
                <span class="title">{{ "TRUCK" | translate }} #</span>
                <span class="desc m-number">{{
                  orderTrip.vehicle?.full_plate_number
                }}</span>
              </div>
              <div class="col-3 w-mobile">
                <span class="title">{{ "MOBILE" | translate }} #</span>
                <span class="desc">
                  {{ orderTrip?.driver?.absher_mobile ?? "-" }}
                </span>
                <span *ngIf="orderTrip?.driver?.mobile" class="desc">
                  {{ orderTrip?.driver?.mobile }}
                </span>
              </div>
              <div class="col-3 w-truck-type">
                <span class="title">{{ "TRUCK_TYPE" | translate }}</span>
                <span
                  class="desc"
                  title="{{ orderTrip?.vehicle?.truck?.name }}"
                  >{{
                    orderTrip?.vehicle?.truck?.name
                      ? orderTrip?.vehicle?.truck?.name
                      : "-"
                  }}</span
                >
              </div>
              <div class="col-3 w-weight">
                <span class="title">{{ "WEIGHT" | translate }}</span>
                <span class="desc">{{
                  this.orderData?.weight + " " + this.measurementUnits.TON
                }}</span>
              </div>

              <div class="col-3 container-section">
                <div class="container-title">
                  <span class="title">{{ "CONTAINER" | translate }} #</span>
                  <div class="add-button">
                    <button
                      *ngIf="orderData?.shahen_order !== 1"
                      class="btn btn-small"
                      (click)="
                        openContainerModal(
                          containerAddEditComponent,
                          orderTrip,
                          false
                        )
                      "
                    >
                      <i class="icon-Plus"></i>
                    </button>
                  </div>
                </div>
                <span
                  *ngFor="
                    let container of orderTrip?.trip_containers.slice(0, 1);
                    let i = index
                  "
                  class="desc"
                >
                  <span title="{{ container?.container_number }}">{{
                    container?.container_number | truncate: 11
                  }}</span>
                </span>
              </div>

              <div class="col-3 container-section">
                <span class="title">{{ "SEAL" | translate }} #</span>
                <div
                  *ngFor="
                    let container of orderTrip?.['trip_containers'].slice(
                      0,
                      1
                    );
                    let i = index
                  "
                  class="seal-details"
                >
                  <span class="desc" title="{{ container?.seal_number }}">{{
                    container?.seal_number ?? '-' | truncate:9
                  }}</span>
                  <div
                    *ngIf="orderData?.shahen_order !== 1"
                    class="seal-buttons"
                  >
                    <a
                      title="{{ 'EDIT' | translate }}"
                      class="icon-edit-box"
                      (click)="
                        openContainerModal(
                          containerAddEditComponent,
                          orderTrip,
                          true,
                          i
                        )
                      "
                    >
                      <i class="icon-edit"></i>
                    </a>
                    <a
                      title="{{ 'DELETE' | translate }}"
                      class="icon-delete-button"
                      (click)="deleteTripModal(container.id, orderTrip)"
                    >
                      <i class="icon-delete"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="trip-data-bottom">
          <div class="trip-left" *ngIf="orderTrip?.is_canceled !== isCanceled">
            <div class="shipment-in-progress">
              <i class="icon-mark"></i>
              <span>{{ this.orderTripStatusList[0]?.label }}</span>
              <div class="trip-date-time">
                <span>{{
                  (orderTrip.trip_statuses[0]?.status === "at_loading_location"
                    ? orderTrip.trip_statuses[0]?.date
                    : orderTrip.trip_statuses[1]?.status ===
                      "at_loading_location"
                    ? orderTrip.trip_statuses[1]?.date
                    : orderTrip.trip_statuses[2]?.date
                  ) | customDate: moment24DateTimeFormat
                }}</span>
              </div>
            </div>
            <div class="shipment-on-way">
              <ng-container
                *ngIf="
                  orderTrip?.next_status === this.orderTripStatusList[1]?.key
                "
              >
                <label for="{{ orderTrip?.id + 'shipment-on-way' }}">
                  <input
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    id="{{ orderTrip?.id + 'shipment-on-way' }}"
                    type="checkbox"
                    class="inActiveTrip"
                    name="{{ orderTrip?.id + 'shipment-on-way' }}"
                    (change)="
                      handleTripStatus(
                        orderTrip?.id,
                        this.orderTripStatusList[1]?.key
                      )
                    "
                    [disabled]="
                      orderTrip?.status === this.orderTripStatusList[1]?.key ||
                      orderTrip?.status === this.orderTripStatusList[2]?.key ||
                      this.orderData?.status === this.orderStatus.CANCELLED
                    "
                  />
                  <div class="toggle">
                    <i class="icon-check"></i>
                    <div class="bullet"></div>
                  </div>
                </label>
                <span
                  >{{ this.orderTripStatusList[1]?.label }}
                  <a
                    *ngIf="orderTrip[this.orderTripStatusList[1]?.key]"
                    (click)="
                      changeTripStatus(
                        orderTrip?.id,
                        this.orderTripStatusList[1]?.key
                      )
                    "
                  >
                    {{ "UPDATE" | translate }}
                  </a>
                </span>
                <div class="trip-date-time">
                  <span>{{
                    (orderTrip.trip_statuses[0]?.status === "on_the_way_trip"
                      ? orderTrip.trip_statuses[0]?.date
                      : orderTrip.trip_statuses[1]?.status === "on_the_way_trip"
                      ? orderTrip.trip_statuses[1]?.date
                      : orderTrip.trip_statuses[2]?.date
                    ) | customDate: moment24DateTimeFormat
                  }}</span>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  orderTrip?.next_status === '' ||
                  orderTrip?.next_status === this.orderTripStatusList[2]?.key
                "
              >
                <i class="icon-mark"></i>
                <span>{{ this.orderTripStatusList[1]?.label }}</span>
                <div class="trip-date-time">
                  <span>{{
                    (orderTrip.trip_statuses[0]?.status === "on_the_way_trip"
                      ? orderTrip.trip_statuses[0]?.date
                      : orderTrip.trip_statuses[1]?.status === "on_the_way_trip"
                      ? orderTrip.trip_statuses[1]?.date
                      : orderTrip.trip_statuses[2]?.date
                    ) | customDate: moment24DateTimeFormat
                  }}</span>
                </div>
              </ng-container>
            </div>
            <div class="delivered">
              <ng-container *ngIf="orderTrip?.next_status === ''">
                <i class="icon-mark"></i>
                <span>{{ this.orderTripStatusList[2]?.label }}</span>
                <div class="trip-date-time">
                  <span>{{
                    (orderTrip.trip_statuses[0]?.status ===
                    "at_unloading_location"
                      ? orderTrip.trip_statuses[0]?.date
                      : orderTrip.trip_statuses[1]?.status ===
                        "at_unloading_location"
                      ? orderTrip.trip_statuses[1]?.date
                      : orderTrip.trip_statuses[2]?.date
                    ) | customDate: moment24DateTimeFormat
                  }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="orderTrip?.next_status !== ''">
                <label for="{{ orderTrip?.id + 'delivered' }}">
                  <input
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    type="checkbox"
                    name="{{ orderTrip?.id + 'delivered' }}"
                    id="{{ orderTrip?.id + 'delivered' }}"
                    class="inActiveTrip"
                    (change)="
                      handleTripStatus(
                        orderTrip?.id,
                        this.orderTripStatusList[2]?.key
                      )
                    "
                    [disabled]="
                      orderTrip?.status !== this.orderTripStatusList[1]?.key ||
                      this.listTripData.status ===
                        this.orderTripStatusList[2]?.key ||
                      this.orderData?.status === this.orderStatus.CANCELLED
                    "
                  />
                  <div class="toggle">
                    <i class="icon-check"></i>
                    <div class="bullet"></div>
                  </div>
                </label>
                <span>{{ this.orderTripStatusList[2]?.label }}</span>
                <a
                  *ngIf="orderTrip[this.orderTripStatusList[2]?.key]"
                  (click)="
                    changeTripStatus(
                      orderTrip?.id,
                      this.orderTripStatusList[2]?.key
                    )
                  "
                >
                  {{ "UPDATE" | translate }}
                </a>
              </ng-container>
            </div>
          </div>
          <div class="trip-left" *ngIf="orderTrip?.is_canceled === isCanceled">
            <div class="canceled">
              <div class="alert-container">
                <div class="alert danger">
                  <div class="cancellation-reason-text">
                    <strong class="reason-title"
                      >{{ "CANCELLATION_REASON" | translate }}:</strong
                    >
                    <span class="cancelled-reason">{{
                      orderTrip?.canceled_reason
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="canceled" *ngIf="orderTrip?.canceled_by">
              <div class="alert-container">
                <div class="alert danger">
                  <span class="reason-text">
                    <strong>{{ "TRIP_CANCEL_BY" | translate }}:</strong>
                    {{ getCancelTripDetailsforSpSr(orderTrip) }}&nbsp;{{
                      "AT" | translate
                    }}&nbsp;{{
                      orderTrip?.cancelled_at | customDate: momentDateTimeFormat
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #editShipmentDateTimePopup>
  <app-edit-shipment-date-time-popup
    (close)="decline()"
    [title]="editDateTimeTitle"
    [tripId]="tripId"
    [dateAndTime]="editModalDate"
    [loadingDateAndTime]="editModalLoadingDate"
    (submitDate)="submitDate($event)"
  >
  </app-edit-shipment-date-time-popup>
</ng-template>
<ng-template #containerAddEditComponent>
  <app-container-add-edit-popup
    [selectedTrip]="selectedChangeTrip"
    [showAddEdit]="orderData?.shahen_order !== 1"
    [editMode]="containerEditMode"
    (refreshData)="refreshData()"
    (close)="decline()"
  >
  </app-container-add-edit-popup>
</ng-template>
